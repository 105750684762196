.device-main-item-lay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin: 0px;
    .device-item-title-lay {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        border-bottom: 1px solid var(--color-border);
        background-color: var(--color-toolbar-bg);
        z-index: 1;
        .device-item-back-btn {
            width: 30px;
            height: 30px;
            font-size: 24px;
            color: var(--color-icon);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 4px;
            cursor: pointer;
        }
        .device-item-title-text {
            font-size: 16px;
            font-weight: bold;
            color: var(--color-edit-txt);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;
        }
        .refresh-btn {
            width: auto;
            height: 30px;
            font-size: 14px;
            letter-spacing: 2px;
            text-decoration: none;
            color: var(--color-view-txt-active);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 4px;
            border-radius: 8px;
            background: var(--color-view-bg-active);
            padding: 4px 8px;
            cursor: pointer;
            &:hover {
                background: var(--color-view-bg-active);
                color: var(--color-view-txt-active);
                //transform: scale(1.04);
            }
        }
    }
    .device-item-contents-lay {
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        .info-lay {
            width: 100%;
            min-height: 66px;
            max-height: 200px;
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-shrink: 0;
            /* background-color: rgb(97, 117, 197); */
            .info-contents {
                width: calc(100% - 16px);
                height: auto;
                max-height: 200px;
                background-color: var(--color-div-bg);
                border-radius: 8px;
                border: 1px solid var(--color-border);
                margin: 8px 8px 4px 8px;
                position: relative;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                //flex-direction: row;
                justify-content: center;
                align-items: center;
                .name-text {
                    height: 100%;
                    font-size: 16px;
                    color: var(--color-edit-txt);
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    text-decoration: none;
                    /* background-color: #b24bb7; */
                    padding: 2px;
                    margin: 4px 0 4px 0;
                    .edit-btn {
                        width: auto;
                        letter-spacing: 2px;
                        font-size: 14px;
                        padding: 4px 8px;
                        box-sizing: border-box;
                        text-decoration: none;
                        cursor: pointer;
                        border-radius: 8px;
                        color: var(--color-view-txt-active);
                        background: var(--color-view-bg-active);
                        &:hover {
                            background: var(--color-view-bg-active);
                            color: var(--color-view-txt-active);
                        }
                    }
                }
            }
        }
        .status-lay {
            width: 100%;
            min-height: 66px;
            max-height: 200px;
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-shrink: 0;
            /* background-color: rgb(97, 117, 197); */
            .status-contents {
                width: calc(100% - 16px);
                height: auto;
                max-height: 200px;
                background-color: var(--color-div-bg);
                border-radius: 8px;
                border: 1px solid var(--color-border);
                margin: 4px 8px 4px 8px;
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .range-display-lay {
                    width: 60%;
                    height: 80%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding: 2px;
                    margin: 4px 0 4px 0;
                    box-sizing: border-box;
                }
                .current-display-lay {
                    width: 40%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    /* background-color: #b24bb7; */
                    padding: 2px;
                    margin: 4px 0 4px 0;
                }
                .range-display-lay .info,
                .current-display-lay .info {
                    font-size: 16px;
                    color: var(--color-edit-txt);
                }
            }
        }
        .empty-range-data {
            width: 100%;
            height: auto;
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            .empty-range-message-box {
                width: calc(100% - 16px);
                color: var(--color-edit-txt);
                font-size: 15px;
                background-color: var(--color-div-bg);
                box-sizing: border-box;
                border-radius: 8px;
                border: 1px solid var(--color-border);
                padding: 12px 20px 12px 20px;
                margin: 4px 8px 4px 8px;
            }
        }
        .device-detail-item-loader {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            /*border-style: solid;*/
            position: absolute;
            z-index: 8;
            box-sizing: border-box;
            display: flex;
            flex-shrink: 0;
        }
        .data-lay {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            .items-lay-first {
                width: calc(100% - 8px);
                height: 200px;
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                overflow: hidden;
                /* background-color: rgb(83, 70, 199); */
                .power-cont,
                .door-cont,
                .light-cont,
                .humi-cont {
                    width: calc(100% / 4);
                    height: calc(100% - 8px);
                    border-width: 1px;
                    border-radius: 8px;
                    /*border-style: solid;*/
                    border-color: var(--color-border);
                    margin: 0 4px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    box-sizing: border-box;
                }
                .item-icon {
                    width: 30%;
                    color: #fff;
                    font-size: 32px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    text-align: right;
                    padding: 16px 12px 0 0;
                    /* background-color: #000; */
                }
                .item-icon-svg {
                    width: 30%;
                    height: 60px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    text-align: right;
                    padding: 16px 4px 0 0;
                    /* background-color: #000; */
                }
                .item-title {
                    width: 60%;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 900;
                    right: 0;
                    text-align: left;
                    margin: 26px 16px 0 12px;
                }
                .item-info {
                    width: 60%;
                    color: rgb(255, 255, 255);
                    font-size: 18px;
                    font-weight: 200;
                    font-style: inherit;
                    right: 0;
                    text-align: left;
                    margin: 0 0 8px 12px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
                .sub-items {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    /* background-color: #000; */
                    margin: 0 0 4px 4px;
                    position: absolute;
                    top: 50px;
                    bottom: 0;
                    left: 0;
                    .sub-item-cont {
                        width: calc(100% - 8px);
                        display: flex;
                        flex-direction: row;
                        position: relative;
                        margin: 4px;
                        .sub-item-title {
                            width: 50%;
                            color: rgb(255, 255, 255);
                            font-size: 16px;
                            font-weight: 600;
                            margin: 8px 4px 8px 0;
                        }
                        .sub-item-info {
                            width: 50%;
                            color: rgb(255, 255, 255);
                            font-size: 14px;
                            font-weight: 400;
                            margin: 8px;
                        }
                    }
                }
            }
            .items-lay-second {
                width: calc(100% - 8px);
                height: 340px;
                position: relative;
                display: flex;
                flex-direction: row;
                overflow: hidden;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                .temp-cont {
                    width: calc(30% - 8px);
                    height: 94%;
                    border-width: 1px;
                    border-radius: 8px;
                    /*border-style: solid;*/
                    border-color: var(--color-border);
                    margin: 4px 4px 0 4px;
                    position: relative;
                    /* background-color: var(--color-div-bg); */
                }
                .loc-cont {
                    width: calc(70% - 8px);
                    height: 94%;
                    border-radius: 8px;
                    border: 1px solid var(--color-border);
                    margin: 4px 4px 0 4px;
                    position: relative;
                    background-color: var(--color-div-bg);
                    display: flex;
                    flex-direction: column;
                }
                .item-icon {
                    width: 30%;
                    color: #fff;
                    font-size: 32px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    text-align: right;
                    padding: 16px 12px 0 0;
                    /* background-color: #000; */
                }
                .item-title {
                    width: 60%;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 900;
                    /* right: 0; */
                    text-align: left;
                    margin: 26px 16px 0 12px;
                }
                .temp-items {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    /* background-color: #000; */
                    margin: 0 0 8px 8px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    .temp-n-cont {
                        width: calc(100% - 8px);
                        display: flex;
                        flex-direction: row;
                        position: relative;
                        /* background-color: #000; */
                        margin: 4px;
                        .temp-n-title {
                            width: 50%;
                            color: rgb(255, 255, 255);
                            font-size: 18px;
                            font-weight: 600;
                            margin: 8px 4px 8px 0;
                        }
                        .temp-n-info {
                            width: 50%;
                            color: rgb(255, 255, 255);
                            font-size: 16px;
                            font-weight: 400;
                            margin: 8px;
                        }
                    }
                }
            }
            .items-lay-third {
                width: 100%;
                height: auto;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                overflow: hidden;
                padding: 0 0 0 0;
                .chart-first-lay,
                .chart-second-lay {
                    width: 100%;
                    height: 300px;
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    margin: 0 0 8px 0;
                }
                .iot-item-chart {
                    width: calc(100% / 2);
                    height: calc(100% - 4px);
                    border-radius: 8px !important;
                    border: 1px solid var(--color-border) !important;
                    margin: 4px 8px 8px 8px;
                    position: relative;
                    background-color: var(--color-div-bg);
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    overflow-x: auto;
                    overflow-y: hidden;
                    .chart-lay {
                        width: 99%;
                        height: 100%;
                        padding: 4px;
                        position: absolute;
                        * {
                            transition: none;
                        }
                    }
                }
            }
        }
    }
    .device-modal {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 0 0 0;
        background-color: rgba(0, 0, 0, 0.645);
        display: flex;
        z-index: 16;
    }
    .setting-lay {
        width: calc(100vw - var(--iot-nav-width));
        height: 100vh;
        position: fixed;
        top: 50px;
        left: var(--iot-nav-width);
        right: 0;
        bottom: 0;
        margin: 0 0 0 0;
        background-color: rgba(0, 0, 0, 0.405);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 10;
        .setting-contents {
            width: 100%;
            height: 85%;
            max-width: 600px;
            max-height: 800px;
            overflow: hidden;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            background-color: var(--color-div-bg);
            border-radius: 8px;
            border: 1px solid var(--color-border);
            margin: -58px 0 8px 0;
            padding: 0;
            .setting-title-lay {
                width: 100%;
                height: 40px;
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                margin: 0 0 0 0;
                background-color: var(--color-toolbar-bg);
                border-bottom: 1px solid var(--color-border);
                .update-btn {
                    width: auto;
                    height: 30px;
                    font-size: 14px;
                    letter-spacing: 2px;
                    text-decoration: none;
                    color: var(--color-view-txt-active);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    left: 4px;
                    border-radius: 8px;
                    background: var(--color-view-bg-active);
                    padding: 4px 8px;
                    cursor: pointer;
                    &:hover {
                        background: var(--color-view-bg-active);
                        color: var(--color-view-txt-active);
                        //transform: scale(1.04);
                    }
                }
                .setting-text {
                    font-size: 16px;
                    font-weight: bold;
                    color: var(--color-edit-txt);
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                }
                .setting-close-btn {
                    width: 30px;
                    height: 30px;
                    font-size: 24px;
                    color: var(--color-icon);
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    right: 4px;
                    cursor: pointer;
                }
            }
            .setting-modal {
                width: 100%;
                height: 100%;
                position: absolute;
                margin: 0 0 0 0;
                background-color: rgba(0, 0, 0, 0.405);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                z-index: 1;
                .setting-error {
                    width: 60%;
                    max-width: 400px;
                    background: white;
                    border: var(--color-border);
                    letter-spacing: 2px;
                    color: #ff0000;
                    padding: 12px 20px;
                    margin: 8px 0 8px 0;
                    box-sizing: border-box;
                    border-radius: 8px;
                    font-size: 16px;
                    text-align: center;
                    cursor: pointer;
                }
            }
            .form-contents {
                width: 100%;
                height: calc(100% - 40px);
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                overflow-x: hidden;
                overflow-y: auto;
                position: relative;
                .input-field-lay {
                    width: calc(100% - 16px);
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    position: relative;
                    padding: 8px 0;
                    //border: 1px solid var(--color-border);
                    //border-radius: 8px;
                    .input-field-text {
                        width: 30%;
                        text-align: left;
                        font-size: 14px;
                        color: var(--color-edit-txt);
                        word-wrap: break-word;
                    }
                    .input {
                        width: 70%;
                        height: auto;
                        background: var(--color-main-screen-bg);
                        border: var(--color-border);
                        border-radius: 8px;
                        letter-spacing: 2px;
                        font-size: 14px;
                        color: var(--color-edit-txt);
                        padding: 8px 8px;
                        margin: 0 0 0 0;
                        box-sizing: border-box;
                        position: relative;
                    }
                }
                .area-type {
                    width: calc(100% - 16px);
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    position: relative;
                    padding: 8px 8px;
                    border: 1px solid var(--color-border);
                    border-radius: 8px;
                    background-color: var(--color-main-screen-bg);
                    .area-type-text {
                        width: 100%;
                        text-align: left;
                        font-size: 14px;
                        color: var(--color-edit-txt);
                        padding: 0;
                        margin: 8 8 4px 0;
                    }
                    .area-type-item {
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        position: relative;
                        cursor: pointer;
                        margin: 4px 0 4px 0;
                        .check-input {
                            width: 20px;
                            height: 20px;
                            position: relative;
                            border-radius: 50%;
                            border: 1px solid var(--color-border);
                            background-color: var(--color-div-bg);
                            padding: 8px;
                            margin: 0 8px 0 0;
                        }
                        .area-type-text {
                            width: auto;
                            text-align: left;
                            font-size: 16px;
                            color: var(--color-edit-txt);
                        }
                    }
                    .input-radius-lay {
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        position: relative;
                        padding: 8px 0 0 0;
                        margin: 8px 0 0 0;
                        border-top: 1px solid var(--color-border);
                        .input-radius-text {
                            width: auto;
                            text-align: left;
                            font-size: 14px;
                            color: var(--color-edit-txt);
                            word-wrap: break-word;
                        }
                        .radius-input {
                            font-size: 14px;
                            color: var(--color-edit-txt);
                            padding: 8px 8px;
                            margin: 0 0 0 8px;
                            background: var(--color-div-bg);
                            border: var(--color-border);
                            border-radius: 8px;
                        }
                    }
                }
                .setting-loc {
                    width: calc(100% - 16px);
                    height: 500px;
                    max-height: 70vh;
                    border-radius: 8px;
                    border: 1px solid var(--color-border);
                    margin: 8px;
                    position: relative;
                    background-color: var(--color-div-bg);
                    display: flex;
                    flex-direction: column;
                    flex-shrink: 0;
                    cursor: pointer !important;
                    z-index: 0;
                }
            }
        }
    }
}

// @media screen and (max-width: 1020px) {
//     .iot-main-contents {
//         width: 100%;
//         position: relative;
//         margin: 0 0 0 0;
//     }
// }
@media screen and (max-width: 1020px) {
    .device-main-item-lay {
        .setting-lay {
            width: 100vw;
            left: 0;
        }
    }
}

@media screen and (max-width: 850px) {
    .device-main-item-lay {
        .device-item-contents-lay {
            .info-lay {
                .info-contents {
                    width: calc(100% - 8px);
                    height: auto;
                    margin: 4px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .name-text {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .status-lay {
                .status-contents {
                    width: calc(100% - 8px);
                    height: auto;
                    margin: 4px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .range-display-lay {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    .current-display-lay {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .empty-range-data {
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                .empty-range-message-box {
                    width: calc(100% - 8px);
                    ;
                    height: auto;
                }
            }
            .data-lay {
                .items-lay-first {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    margin: 0;
                    .power-cont,
                    .door-cont,
                    .light-cont,
                    .humi-cont {
                        width: calc(100% - 8px);
                        height: 190px;
                        margin: 4px 0 4px 0;
                    }
                    .item-icon {
                        width: 135px;
                        padding: 34px 34px 0 0;
                    }
                    .item-icon-svg {
                        width: 115px;
                        height: 80px;
                        padding: 34px 0 0 0;
                    }
                    .item-title {
                        width: 60%;
                        color: #fff;
                        font-size: 24px;
                        right: 0;
                        text-align: left;
                        margin: 26px 16px 0 12px;
                    }
                    // .item-info {
                    //     width: 60%;
                    //     font-size: 18px;
                    //     font-weight: bold;
                    //     font-style: inherit;
                    //     right: 0;
                    //     text-align: left;
                    //     margin: 0 0 8px 12px;
                    // }
                }
                .items-lay-second {
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    .temp-cont {
                        width: calc(100% - 4px);
                        height: auto;
                        height: 250px;
                        margin: 4px;
                    }
                    .loc-cont {
                        width: calc(100% - 4px);
                        margin: 4px;
                        height: calc(95vh - (50px + 40px + 8px));
                    }
                    .item-icon {
                        width: 135px;
                        padding: 34px 34px 0 0;
                        /* background-color: #000; */
                    }
                }
                .items-lay-third {
                    .chart-first-lay,
                    .chart-second-lay {
                        width: 100%;
                        height: 600px;
                        flex-direction: column;
                    }
                    .iot-item-chart {
                        width: calc(100% - 8px);
                        margin: 4px 8px 4px 8px;
                    }
                    //// new date-range-picker 
                    // .rs-picker-toggle-textbox {
                    //     width: 80% !important;
                    //     padding: 0 !important;
                    // }
                }
            }
        }
        .setting-lay {
            .setting-contents {
                width: 100%;
                height: 100%;
                margin: 0 0 50px 0;
                border-width: 0px;
                border-radius: 0px;
            }
        }
    }
}