.login-main {
    width: 100%;
    height: 100vh;
    background-color: #7e7e7e;
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-main .form-container {
    width: 300px;
    display: inline-block;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-main .form-container span {
    letter-spacing: 2px;
    color: #eeeeee;
    padding: 12px 20px;
    margin: 4px 0;
    cursor: pointer;
}

.login-main .form-container .input {
    width: 100%;
    background: #070707;
    border: #8b8b8b;
    letter-spacing: 2px;
    color: #eeeeee;
    /* width: 50%; */
    padding: 12px 20px;
    /* margin: 8px 0; */
    box-sizing: border-box;
    border-radius: 8px;
    margin: 8px 0 8px 0;
}

.login-main .form-container .login-btn {
    width: 100%;
    background: #070707;
    border: #8b8b8b;
    letter-spacing: 2px;
    color: #eeeeee;
    padding: 12px 20px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 8px;
    margin: 8px 0 8px 0;
}

.login-main .form-container .login-btn:hover {
    /* // transform: scale(1.04); */
    background: rgb(55, 0, 206);
}

.login-main .form-container .loading-icon {
    /* width: 300px; */
    color: rgb(255, 255, 255);
    font-size: 34px;
    font-weight: bold;
    padding: 4px;
    margin: 12px 0 0 0;
    position: relative;
    left: 0;
    right: 0;
    box-sizing: border-box;
}

.login-main .form-container .message-box {
    color: rgb(255, 42, 42);
    font-size: 12px;
    font-weight: bold;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px;
}