.account-main {
    width: calc(100% - 16px);
    height: calc(100% - 8px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: var(--color-div-bg);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    margin: 4px 8px;
    padding: 0;
    .account-title-lay {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        border-bottom: 1px solid var(--color-border);
        background-color: var(--color-toolbar-bg);
        z-index: 1;
        .account-back-btn {
            width: 30px;
            height: 30px;
            font-size: 24px;
            color: var(--color-icon);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 4px;
            cursor: pointer;
        }
        .account-title-text {
            font-size: 16px;
            font-weight: bold;
            color: var(--color-edit-txt);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;
        }
    }
    .account-contents-lay {
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        .account-first-lay {
            width: calc(100% - 16px);
            height: auto;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            border: 1px solid var(--color-border);
            border-radius: 8px;
            background-color: var(--color-div-bg);
            margin: 4px 8px 4px 8px;
            padding: 8px;
            .account-avatar-cont {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                border: 1px solid var(--color-border);
                background-color: var(--color-main-screen-bg);
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                position: relative;
                .account-avatar-top {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                }
                .account-avatar-img {
                    width: 116px;
                    height: 116px;
                    border-radius: 50%;
                    padding: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    object-fit: cover;
                    image-rendering: pixelated;
                }
            }
            .account-text-cont {
                width: calc(100% - 120px - 16px - 16px);
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                position: relative;
                margin: 0 0 0 8px;
                .account-text-lay {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    position: relative;
                    .account-text-name {
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        position: relative;
                        font-size: 20px;
                        font-weight: bold;
                        color: var(--color-edit-txt);
                        margin: 8px;
                    }
                    .account-text-1,
                    .account-text-2 {
                        width: auto;
                        height: auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        position: relative;
                        font-size: 16px;
                        font-weight: 300;
                        color: var(--color-edit-txt);
                        padding: 4px;
                    }
                    .account-text-1 {
                        width: 100px !important;
                        font-weight: bold;
                        margin: 0 0 0 6px;
                    }
                }
            }
        }
        .account-second-lay {
            width: 100%;
            height: auto;
            margin: 8px 0px 0px 0px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            .second-list-title {
                width: 100%;
                height: 40px;
                font-size: 16px;
                font-weight: bold;
                color: var(--color-edit-txt);
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                position: relative;
                margin: 8px;
            }
            .second-list-lay {
                width: 100%;
                height: auto;
                margin: 8px 0px 8px 0px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                * {
                    text-decoration: none !important;
                }
                .device-item-lay {
                    width: calc(100% - 16px);
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    position: relative;
                    border: 1px solid var(--color-border);
                    border-radius: 8px;
                    background-color: var(--color-div-bg);
                    margin: 4px 8px 4px 8px;
                    padding: 8px;
                    text-decoration: none;
                    .device-item-left {
                        width: 50px;
                        height: 70px;
                        font-size: 26px;
                        color: var(--color-icon);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        margin: 0 8px 0 0;
                    }
                    .device-item-links-lay {
                        width: calc(100% - 50px - 8px);
                        height: auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        position: relative;
                        margin: 0 8px 0 0;
                        .device-item-center {
                            width: calc(100% - (100px + 8px + 8px));
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: center;
                            position: relative;
                            margin: 0 8px 0 0;
                            .item-name-lay {
                                width: 100%;
                                height: auto;
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: center;
                                position: relative;
                                .item-name-text-1 {
                                    width: 100px;
                                    height: auto;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;
                                    align-items: center;
                                    position: relative;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: var(--color-edit-txt);
                                    margin: 0 0 0 8px;
                                }
                                .item-name-text-2 {
                                    width: calc(100% - (100px + 8px));
                                    height: auto;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;
                                    align-items: center;
                                    position: relative;
                                    font-size: 14px;
                                    font-weight: 200;
                                    color: var(--color-edit-txt);
                                    word-spacing: 1px;
                                    letter-spacing: 1px;
                                    margin: 0 0 0 8px;
                                }
                            }
                        }
                        .device-item-right {
                            width: 100px;
                            height: 40px;
                            letter-spacing: 2px;
                            font-size: 15px;
                            color: var(--color-view-txt-active);
                            text-decoration: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            padding: 4px 8px;
                            margin: 8px;
                            cursor: pointer;
                            border-radius: 8px;
                            // transform: scale(1);
                            background: var(--color-view-bg-active);
                            &:hover {
                                background: var(--color-view-bg-active);
                                color: var(--color-view-txt-active);
                                // transform: scale(1.04);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .account-main {
        width: 100%;
        height: 100%;
        border-width: 0px;
        border-radius: 0px;
        margin: 0px;
        padding: 0;
        background-color: var(--color-iot-bg);
        .account-contents-lay {
            .account-first-lay {
                flex-direction: column;
                .account-text-cont {
                    width: calc(100% - 16px);
                    margin: 0 0 0 0;
                    .account-text-lay {
                        .account-text-name {
                            margin: 8px;
                        }
                    }
                }
            }
            .account-second-lay {
                .second-list-lay {
                    .device-item-lay {
                        .device-item-links-lay {
                            width: calc(100% - 50px - 8px);
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            margin: 0 8px 0 0;
                            .device-item-center {
                                width: calc(100% - 8px);
                            }
                        }
                    }
                }
            }
        }
    }
}