.side-nav-lay {
    width: var(--iot-nav-width);
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 25;
    display: flex;
    flex-direction: column;
    background-color: var(--color-side-nav);
    border-right: 1px solid var(--color-border);
    transition: none;
    * {
        transition: none;
    }
    .nav-home {
        width: 100%;
        height: var(--iot-header-height);
        position: relative;
        z-index: 99;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;
        padding: 0 0 0 20px;
        margin: 0;
        text-decoration: none;
        cursor: pointer;
        .item-icon {
            font-size: 22px;
            color: var(--color-icon);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            margin: 0 18px 0 0;
        }
        .item-text {
            font-size: 18px;
            font-weight: bold;
            color: var(--color-edit-txt);
        }
    }
    .side-nav-list-lay {
        width: 100%;
        height: calc(100vh - (var(--iot-header-height) + 60px));
        position: relative;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 0 0 14px 0;
        .dash-btn {
            width: calc(100% - 8px);
            height: 40px;
            margin: 2px 0 2px 4px;
            padding: 10px 4px;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-shrink: 0;
            position: relative;
            cursor: pointer;
            border-radius: 8px;
            .item-icon {
                font-size: 20px;
                color: var(--color-icon);
                color: var(--color-view-txt);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0 8px 0 16px;
                position: absolute;
                left: 0;
            }
            .item-text {
                // font-weight: 400;
                color: var(--color-edit-txt);
                color: var(--color-view-txt);
                font-family: Lato, sans-serif;
                font-size: 1rem;
                left: 40px;
                letter-spacing: 2px;
                margin: 0 8px 0 16px;
                position: absolute;
            }
        }
        .dash-btn-normal {
            background: var(--color-view-bg);
            .item-icon {
                color: var(--color-icon);
                color: var(--color-view-txt);
            }
            .item-text {
                color: var(--color-edit-txt);
                color: var(--color-view-txt);
            }
            &:hover {
                background: var(--color-view-bg-active);
                .item-icon {
                    color: var(--color-view-txt-active);
                }
                .item-text {
                    color: var(--color-view-txt-active);
                }
            }
        }
        .dash-btn-selected {
            background: var(--color-view-bg-active);
            .item-icon {
                color: var(--color-icon);
                color: var(--color-view-txt-active);
            }
            .item-text {
                color: var(--color-edit-txt);
                color: var(--color-view-txt-active);
            }
            &:hover {
                background: var(--color-view-bg-active);
                .item-icon {
                    color: var(--color-icon);
                    color: var(--color-view-txt-active);
                }
                .item-text {
                    color: var(--color-edit-txt);
                    color: var(--color-view-txt-active);
                }
            }
        }
        .footer-cont {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            padding: 0 16px 0 16px;
            margin: 24px 0 8px 0;
            /* background-color: rgb(60, 60, 60); */
            a {
                color: var(--color-txt-pri);
                text-decoration: none !important;
                &:hover {
                    text-decoration: underline !important;
                }
            }
            span {
                color: var(--color-edit-txt-sec);
            }
            .footer-seg {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 0;
                margin: 0;
            }
            * {
                font-size: 13px;
                padding: 0;
                margin: 2px 4px 2px 4px;
                display: flex;
                flex-direction: row;
            }
        }
        // .nav-title {
        //     height: 50px;
        //     font-size: 15px;
        //     color: var(--color-edit-txt);
        //     position: relative;
        //     z-index: 3;
        //     display: flex;
        //     flex-direction: column;
        //     padding: 0 0 0 30px;
        //     margin: 8px 0 8px 0;
        //     justify-content: center;
        // }
    }
    .logout-btn-cont {
        width: 100%;
        height: 60px;
        position: absolute;
        bottom: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        box-sizing: border-box;
        // box-shadow: -1px -1px 3px -1px var(--color-shadow);
        .logout-btn {
            width: calc(100% - 8px);
            height: 40px;
            margin: 2px 0 2px 4px;
            padding: 10px 4px;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-shrink: 0;
            position: relative;
            cursor: pointer;
            border-radius: 8px;
            background: var(--color-view-bg);
            .logout-icon {
                color: var(--color-icon);
                color: var(--color-view-txt);
            }
            .logout-text {
                color: var(--color-edit-txt);
                color: var(--color-view-txt);
            }
            &:hover {
                background: var(--color-view-bg-active);
                .logout-icon {
                    color: var(--color-view-txt-active);
                }
                .logout-text {
                    color: var(--color-view-txt-active);
                }
            }
            .logout-icon {
                font-size: 20px;
                color: var(--color-icon);
                color: var(--color-view-txt);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0 8px 0 16px;
                position: absolute;
                left: 0;
            }
            .logout-text {
                // font-weight: 400;
                color: var(--color-edit-txt);
                color: var(--color-view-txt);
                font-family: Lato, sans-serif;
                font-size: 1rem;
                left: 40px;
                letter-spacing: 2px;
                margin: 0 8px 0 16px;
                position: absolute;
            }
        }
    }
}

// .side-nav-lay-top {
//     width: var(--iot-nav-width-final);
//     height: 100vh;
//     position: fixed;
//     left: 0;
//     top: 0;
//     bottom: 0;
//     z-index: 17;
//     display: flex;
//     // animation-name: show-side-nav;
//     // animation-duration: 0.2s;
//     flex-direction: column;
//     // transition: none;
//     // * {
//     //     transition: none;
//     // }
//     // transition-property: left;
//     // transition-duration: 1s;
//     // transition-delay: 2s;
// }
@keyframes show-side-nav {
    from {
        left: var(--iot-nav-width-final-neg);
        // opacity: 0
    }
    to {
        left: 0;
        // opacity: 1
    }
}

@keyframes hide-side-nav {
    from {
        left: 0;
        // opacity: 1
    }
    to {
        left: var(--iot-nav-width-final-neg);
        // opacity: 0
    }
}

@media screen and (min-width: 1021px) {
    .side-nav-lay-top {
        display: none;
        // left: var(--iot-nav-width-final-neg);
    }
    .side-nav-lay {
        display: flex !important;
        // left: 0 !important;
    }
}

@media screen and (max-width: 1020px) {
    .side-nav-lay-top {
        display: flex !important;
        width: var(--iot-nav-width-final);
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 17;
        animation-name: show-side-nav;
        animation-duration: 0.2s;
        flex-direction: column;
        transition: none;
        * {
            transition: none;
        }
        // transition-property: left;
        // transition-duration: 1s;
        // transition-delay: 2s;
    }
    .side-nav-lay {
        display: none;
        // transition-property: left;
        // transition-duration: 1s;
        // transition-delay: 2s;
    }
}