// html,
// body {
//     margin: 0 auto;
//     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     background-color: var(--color-background) !important;
//     overflow-x: hidden;
//     overflow-y: auto;
//     box-sizing: border-box;
//     position: relative;
// }
// body.modal-open {
//     overflow: hidden !important;
//     /* position: fixed !important; */
//     /* width: 100vw !important; */
// }
// .light {
//     --color-primary: #FFFFFF;
//     --color-div-bg: #FFFFFF;
//     /* --color-background: #F9F5FE; */
//     --color-background: #f0f0f0;
//     --color-view-background: #F0F0F0;
//     --color-view-background-hover: #0096fa;
//     --color-text-primary: #272727;
//     --color-edit-txt: #000000;
//     --color-edit-txt-inverted: #ebebeb;
//     --color-text-title: #174DD5;
//     --color-text-footer: #3a3a3a;
//     --color-accent: #E4E4E4;
//     --color-accent-dim: #B8B7B7;
//     --color-stroke: rgba(109, 109, 109, 0.5);
//     --color-border: #707070;
//     --color-border-partial: #484848;
//     --color-shadow: rgba(0, 0, 0, 0.419);
//     --color-icon: #00000070;
//     --color-pallete-icon: #3330eb;
//     --color-pallete-toggle: #ffffff;
//     --color-loader: #a1a1a1;
//     --color-loader-dim: #434343;
//     --color-device-item-normal: #ffffff;
//     --color-device-item-selected: #53aae4;
//     --color-device-item-text-normal: #434343;
//     --color-device-item-text-selected: #ffffff;
//     --color-btn: #174DD5;
//     --color-btn-active: #494949;
//     --color-btn-text: #f7f7f7;
//     --color-active: #4be210;
//     /* #eeeeee9d*/
//     --color-knob-back: rgba(186, 186, 186, 0.535);
//     /* #496cc4*/
//     --color-knob-front: rgb(252, 0, 189);
//     --color-hovered-btn: rgb(238, 238, 238);
//     --color-side-nav: #FFFFFF;
//     /* --color-side-nav: #c4e1ff; */
// }
// .dark {
//     --color-primary: #2D2D2D;
//     --color-div-bg: #333333;
//     --color-background: #1E1E1E;
//     --color-view-background: #3C3C3C;
//     --color-view-background-hover: #006db6;
//     --color-text-primary: #f2f2f2;
//     --color-edit-txt: #ebebeb;
//     --color-edit-txt-inverted: #999999;
//     --color-text-title: #7BDCDA;
//     --color-text-footer: #CCCCCC;
//     --color-accent: #7b7b7b;
//     --color-accent-dim: #767676;
//     --color-stroke: rgba(143, 143, 143, 0.5);
//     --color-border: #444444;
//     --color-border-partial: #d1d1d1;
//     --color-shadow: #111111;
//     --color-icon: #e1e1e1;
//     --color-pallete-icon: #ffffff;
//     --color-pallete-toggle: #2D2D2D;
//     /* --color-pallete-toggle: #13dc2a; */
//     --color-loader: #686868;
//     --color-loader-dim: #C9C9C9;
//     --color-device-item-normal: #3f3f3f;
//     --color-device-item-selected: #1E1E1E;
//     --color-device-item-text-normal: #dddddd;
//     --color-device-item-text-selected: #ffffff;
//     /* --color-device-item-selected: #0a0a0a; */
//     /* --color-device-item-selected-hover: #3f3f3f; */
//     --color-btn: #496cc4;
//     --color-btn-active: #494949;
//     --color-btn-text: #f7f7f7;
//     --color-active: rgba(85, 255, 0, 0.2);
//     --color-knob-back: rgba(186, 186, 186, 0.535);
//     --color-knob-front: rgb(255, 0, 247);
//     --color-hovered-btn: rgb(104, 104, 104);
//     --color-side-nav: #2D2D2D;
// }
// /* * {
//     scrollbar-color: #6969dd #e0e0e0;
//     scrollbar-width: thin;
//     scrollbar-gutter: stable;
// } */
// @media (hover: hover) {
//     ::-webkit-scrollbar {
//        width: 8px;
//        border: 5px solid transparent;
//        background-color: inherit;
//    }
//     ::-webkit-scrollbar-thumb {
//        background-color: var(--color-accent);
//        background-clip: padding-box;
//        border: 0.1em solid transparent;
//        border-radius: 4px;
//    }
//     ::-webkit-scrollbar-thumb:hover {
//        background: var(--color-btn-active);
//        background-clip: padding-box;
//        border: 0.1em solid transparent;
//    }
//     ::-webkit-scrollbar-track {
//        background-color: transparent;
//    }
// }
// ::-webkit-scrollbar {
//     width: 13px;
//     border: 5px solid transparent;
//     background-color: var(--color-background);
// }
// ::-webkit-scrollbar-thumb {
//     background-color: var(--color-accent-dim);
//     background-clip: padding-box;
//     border: 0.1em solid transparent;
// }
// ::-webkit-scrollbar-thumb:hover {
//     background: var(--color-accent-dim);
//     background-clip: padding-box;
//     border: 0.1em solid transparent;
// }
//  ::-webkit-scrollbar-track {
//     background-color: transparent;
// }
// /* Buttons */
//  ::-webkit-scrollbar-button:single-button {
//     background-color: transparent;
//     display: block;
//     border-style: solid;
//     height: 10px;
//     width: 13px;
// }
// /* Up */
//  ::-webkit-scrollbar-button:single-button:vertical:decrement {
//     border-width: 0 8px 8px 8px;
//     border-color: transparent transparent var(--color-accent-dim) transparent;
// }
//  ::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
//     border-color: transparent transparent var(--color-accent-dim) transparent;
// }
// /* Down */
//  ::-webkit-scrollbar-button:single-button:vertical:increment {
//     border-width: 8px 8px 0 8px;
//     border-color: var(--color-accent-dim) transparent transparent transparent;
// }
//  ::-webkit-scrollbar-button:vertical:single-button:increment:hover {
//     border-color: var(--color-accent-dim) transparent transparent transparent;
// }
// code {
//     font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
// }
// /* #root {
//   width: 100%;
//   padding: 0px;
//   justify-content: center;
//   display: flex;
//   flex-direction: column;
//   position: relative;
// } */
// /* .App {
//   width: 100%;
//   padding: 0px;
//   justify-content: center;
//   display: flex;
//   flex-direction: column;
//   position: relative;
// } */
// /* @media (max-width: 900px) {
//   .App {
//       padding: 0px 10rem 0px 10rem;
//       width: 80rem;
//   }
// } */
// .border-shadow {
//     box-shadow: 0 0 3px -1px var(--color-shadow);
//     /* box-shadow: h-offset v-offset blur spread color; */
// }
// .selector-none {
//     -moz-user-select: none;
//     -webkit-user-select: none;
//     -ms-user-select: none;
//     -o-user-select: none;
//     user-select: none;
// }
// .blue-back-grad {
//     background: #1A2980;
//     background: -webkit-linear-gradient(to bottom, #26D0CE, #1A2980);
//     background: linear-gradient(to bottom, #26D0CE, #1A2980);
// }
// .red-grad {
//     /* background: -webkit-linear-gradient(to bottom, #F45C43, #EB3349); */
//     /* background: linear-gradient(to bottom, #F45C43, #EB3349); */
//     /* background: #e52d27; */
//     /* background: -webkit-linear-gradient(to bottom, #b31217, #e52d27); */
//     /* background: linear-gradient(to bottom, #b31217, #e52d27); */
//     /* background: rgb(2, 0, 36); */
//     /* background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(112, 21, 86, 1) 0%, rgba(255, 0, 0, 1) 62%); */
//     background: #FF0401;
//     background: -webkit-linear-gradient(top, #FF0401, #6A0101);
//     background: -moz-linear-gradient(top, #FF0401, #6A0101);
//     background: linear-gradient(to bottom, #FF0401, #6A0101);
// }
// .blue-grad {
//     /* background: #1A2980; */
//     /* background: -webkit-linear-gradient(to bottom, #26D0CE, #1A2980); */
//     /* background: linear-gradient(to bottom, #26D0CE, #1A2980); */
//     background: #4b6cb7;
//     background: -webkit-linear-gradient(to bottom, #4b6cb7, #182848);
//     background: linear-gradient(to bottom, #4b6cb7, #182848);
// }
// .purple-grad {
//     background: #DA22FF;
//     background: -webkit-linear-gradient(to bottom, #DA22FF, #9733EE);
//     background: linear-gradient(to bottom, #DA22FF, #9733EE);
// }
// .temp-grad {
//     background: #02AAB0;
//     background: -webkit-linear-gradient(to bottom, #00CDAC, #02AAB0);
//     background: linear-gradient(to bottom, #00CDAC, #02AAB0);
// }
// .back-grad {
//     background: #1A2980;
//     background: -webkit-linear-gradient(to bottom, #26D0CE, #1A2980);
//     background: linear-gradient(to bottom, #26D0CE, #1A2980);
// }
// .paleblue-grad {
//     background: #2193b0;
//     background: -webkit-linear-gradient(to bottom, #6dd5ed, #2193b0);
//     background: linear-gradient(to bottom, #6dd5ed, #2193b0);
// }
// .side-grad {
//     /* background: #2193b0; */
//     /* background: -webkit-linear-gradient(to bottom, #6dd5ed, #2193b0); */
//     /* background: linear-gradient(to bottom, #6dd5ed, #2193b0); */
//     background-color: var(--color-side-nav);
// }
// .loading-shimmer {
//     background-color: var(--color-loader);
//     background-image: -webkit-gradient(linear, left top, right top, from(var(--color-loader)), color-stop(var(--color-loader-dim)), to(var(--color-loader)));
//     background-image: linear-gradient(90deg, var(--color-loader), var(--color-loader-dim), var(--color-loader));
//     background-repeat: no-repeat;
//     background-size: 100% 100%;
//     /* border-radius: 4px; */
//     /* display: inline-block; */
//     line-height: 1;
//     /* width: 100% */
//     -webkit-animation: shimmerLoader 1.8s ease-in-out infinite;
//     animation: shimmerLoader 1.8s ease-in-out infinite
// }
// @-webkit-keyframes shimmerLoader {
//     0% {
//         background-position: -500px 0
//     }
//     to {
//         background-position: calc(500px + 100%) 0
//     }
// }
// @keyframes shimmerLoader {
//     0% {
//         background-position: -500px 0
//     }
//     to {
//         background-position: calc(500px + 100%) 0
//     }
// }
// @keyframes active-ripple {
//     0% {
//         box-shadow: 0 0 0 .1rem rgba(85, 255, 0, 0.3), 0 0 0 .5rem rgba(85, 255, 0, 0.2), 0 0 0 .5rem rgba(85, 255, 0, 0.2);
//     }
//     100% {
//         box-shadow: 0 0 0 .4rem rgba(85, 255, 0, 0.2), 0 0 0 .6rem rgba(85, 255, 0, 0.2), 0 0 0 1.0rem rgba(85, 255, 0, 0);
//     }
//     /* 0% {
//         box-shadow: 0 0 0 .7rem rgba(85, 255, 0, 0.2), 0 0 0 1.5rem rgba(85, 255, 0, 0.2), 0 0 0 5rem rgba(85, 255, 0, 0.2);
//     }
//     100% {
//         box-shadow: 0 0 0 1.5rem rgba(85, 255, 0, 0.2), 0 0 0 4rem rgba(85, 255, 0, 0.2), 0 0 0 8rem rgba(85, 255, 0, 0);
//     } */
//     /* 0% {
//         box-shadow: 0 0 0 0rem rgba(var(--color-active), 0.2), 0 0 0 1rem rgba(var(--color-active), 0.2), 0 0 0 2rem rgba(var(--color-active), 0.2), 0 0 0 5rem rgba(var(--color-active), 0.2);
//     }
//     100% {
//         box-shadow: 0 0 0 1rem rgba(var(--color-active), 0.2), 0 0 0 2rem rgba(var(--color-active), 0.2), 0 0 0 5rem rgba(var(--color-active), 0.2), 0 0 0 8rem rgba(var(--color-active), 0);
//     } */
// }
// span,
// p,
// div {
//     -moz-user-select: none;
//     -webkit-user-select: none;
//     -ms-user-select: none;
//     -o-user-select: none;
//     user-select: none;
//     -webkit-user-drag: none;
// }
html,
body {
    margin: 0 auto;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-main-screen-bg) !important;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    position: relative;
    width: 100vw;
    height: 100vh;
    // @extend .def-vars;
}

* {
    box-sizing: border-box;
    transition: all .01s ease-in-out;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    src: local('Quicksand Regular'), local('Quicksand-Regular'), url(https://fonts.gstatic.com/s/quicksand/v7/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body.modal-open {
    overflow: hidden !important;
    /* position: fixed !important; */
    /* width: 100vw !important; */
}

.modal-shadow {
    background: rgba(0, 0, 0, 0.481);
    animation: modal-bg-fade-in 0.5s ease-in-out;
}

@keyframes modal-bg-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#root {
    --iot-radius: 8px;
    --iot-nav-width: 190px;
    --iot-nav-width-final: 190px;
    --iot-nav-width-final-neg: -190px;
    --iot-chat-width: 300px;
    --iot-live-height: 70%;
    --iot-header-height: 50px;
}

@media (max-width: 900px) {
    #root {
        --iot-chat-width: 0px !important;
    }
}

@media (max-width: 750px) {
    #root {
        --iot-nav-width: 0px !important;
    }
}

::-webkit-calendar-picker-indicator {
    // filter: invert(1);
    color: var(--color-icon) !important;
    padding: 0 !important;
    margin: 0 !important;
}

.light {
    color-scheme: light !important;
    --color-main-screen-bg: #c9c9c9;
    --color-main-screen-bg: #eaeeef;
    --color-iot-bg: #ededed;
    --color-iot-bg: #eaeeef;
    --color-toolbar-bg: #FFFFFF;
    --color-div-bg: #FFFFFF;
    --color-side-nav: #ffffff;
    --color-border: #cacaca;
    --color-border-partial: #484848;
    /*#707070*/
    /*  */
    // --color-txt-pri: #4356ff;
    // --color-txt-pri-hover: #6877fc;
    // --color-edit-txt: #000000;
    // --color-edit-txt-sec: #212121;
    --color-txt-pri: #004ac1;
    --color-txt-pri-hover: #005bee;
    --color-edit-txt: #000000;
    --color-edit-txt-sec: #212121;
    // --color-text-title: #174DD5;
    /*  */
    --color-view-bg: #f0f0f000;
    --color-view-bg-sec: #E4E6E9;
    --color-view-bg-sec-deep: #a1a1a1;
    --color-view-bg-sec-active: #cacaca;
    // --color-view-bg-active: #7b88ff;
    // --color-view-bg-hover: #7b88ff;
    // --color-view-bg-active-hover: #7b88ff;
    --color-view-bg-active: #377def;
    --color-view-bg-hover: #377defe2;
    --color-view-bg-active-hover: #377defe7;
    /*  */
    --color-view-txt: #000000;
    --color-view-txt-active: #ffffff;
    --color-view-txt-hover: #ffffff;
    --color-view-txt-sec: #212121;
    /*  */
    --color-view-0-bg: #f0f0f000;
    --color-view-0-bg-active: #F0EEF0;
    --color-view-0-bg-hover: #dcdcdc;
    --color-view-0-bg-active-hover: #cacaca;
    /*  */
    --color-view-0-txt: #000000;
    --color-view-0-txt-active: #212121;
    --color-view-0-txt-hover: #000000;
    /*  */
    // --color-view-def-bg: #2A84FF;
    // --color-view-def-bg-hover: #2A84FFe2;
    // --color-view-def-txt: #FFFFFF;
    // --color-view-def-txt-hover: #FFFFFF;
    --color-view-def-bg: #004ac1;
    --color-view-def-bg-hover: #004ac1e2;
    --color-view-def-txt: #FFFFFF;
    --color-view-def-txt-hover: #FFFFFF;
    /*  */
    --color-accent: #93d9ff;
    --color-icon: #000000cd;
    --color-shadow: rgba(0, 0, 0, 0.419);
    /*  */
    --color-pallete-icon: #3330eb;
    --color-pallete-toggle: #ffffff;
    /*  */
    --color-loader: #a1a1a1;
    --color-loader-dim: #434343;
}

.dark {
    color-scheme: dark !important;
    --color-main-screen-bg: #121316;
    --color-main-screen-bg: #000000;
    --color-iot-bg: #000000; //#151515;
    // --color-toolbar-bg: #2D2D2D;
    --color-toolbar-bg: #1B1C1F;
    // --color-div-bg: #2D2D2D;
    --color-div-bg: #1B1C1F; //#1d1e23;
    // --color-side-nav: #2D2D2D;
    --color-side-nav: #1B1C1F;
    --color-border: #2a2c30;
    --color-border-partial: #d1d1d1;
    /*  */
    // --color-txt-pri: #7b88ff;
    // --color-txt-pri-hover: #949efd;
    // --color-edit-txt: #ffffff;
    // --color-edit-txt-sec: #d5d5d5;
    --color-txt-pri: #7b88ff;
    --color-txt-pri-hover: #949efd;
    --color-edit-txt: #ffffff;
    --color-edit-txt-sec: #d5d5d5;
    /*  */
    --color-view-bg: #15151500;
    --color-view-bg-sec: #3C3C3C;
    --color-view-bg-sec-deep: #525252;
    --color-view-bg-sec-active: #595959;
    // --color-view-bg-active: #7b88ff;
    // --color-view-bg-hover: #7b88ff;
    // --color-view-bg-active-hover: #7b88ff;
    --color-view-bg-active: #3779e3;
    --color-view-bg-hover: #3779e3e2;
    --color-view-bg-active-hover: #3779e3e7;
    /*  */
    --color-view-txt: #d3d3d3;
    --color-view-txt-active: #ffffff;
    --color-view-txt-hover: #ffffff;
    --color-view-txt-sec: #d5d5d5;
    /*  */
    --color-view-0-bg: #f0f0f000;
    --color-view-0-bg-active: #595959;
    --color-view-0-bg-hover: #595959e0;
    --color-view-0-bg-active-hover: #595959e0;
    /*  */
    --color-view-0-txt: #dcdcdc;
    --color-view-0-txt-active: #f2f2f2;
    --color-view-0-txt-hover: #ffffff;
    /*  */
    // --color-view-def-bg: #2A84FF;
    // --color-view-def-bg-hover: #2A84FFe2;
    // --color-view-def-txt: #FFFFFF;
    // --color-view-def-txt-hover: #FFFFFF;
    --color-view-def-bg: #004ac1;
    --color-view-def-bg-hover: #004ac1e2;
    --color-view-def-txt: #FFFFFF;
    --color-view-def-txt-hover: #FFFFFF;
    /*  */
    --color-accent: #93d9ff;
    --color-icon: #e1e1e1;
    --color-shadow: #222222;
    /*  */
    --color-pallete-icon: #ffffff;
    --color-pallete-toggle: #2D2D2D;
    /*  */
    --color-loader: #a1a1a1;
    --color-loader-dim: #434343;
}

@media (hover: hover) {
     ::-webkit-scrollbar {
        width: 8px;
        border: 5px solid transparent;
        background-color: inherit;
    }
     ::-webkit-scrollbar-thumb {
        background-color: var(--color-view-bg-sec-deep);
        background-clip: padding-box;
        border: 0.1em solid transparent;
        border-radius: var(--iot-radius);
    }
     ::-webkit-scrollbar-thumb:hover {
        background: var(--color-view-bg-active);
        background-clip: padding-box;
        border: 0.1em solid transparent;
    }
     ::-webkit-scrollbar-track {
        background-color: transparent;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.border-shadow {
    box-shadow: 0 0 3px -1px var(--color-shadow);
    /* box-shadow: h-offset v-offset blur spread color; */
}

.view-shadow {
    box-shadow: 0 0 4px 0px var(--color-border);
    /* box-shadow: h-offset v-offset blur spread color; */
}

.border-bottom {
    box-shadow: 2px 0 1px 1px var(--color-border);
    /* box-shadow: h-offset v-offset blur spread color; */
}

.border-right {
    box-shadow: 0 1px 0px 1px var(--color-border);
    /* box-shadow: 0 51px 0px 1px var(--color-border); */
    /* box-shadow: h-offset v-offset blur spread color; */
}

@media screen and (max-width: 1000px) {
    .cont-wm {
        width: 100vw !important;
        margin: 0 0 0 220px !important;
    }
    .cont-fwm {
        width: 100% !important;
        margin: 0 -220px 0 0;
        animation-name: open-nav-anim-fwm;
        animation-duration: .2s;
    }
    @keyframes open-nav-anim-fwm {
        0% {
            margin: 0 0 0 0;
        }
        100% {
            margin: 0 -220px 0 0;
        }
    }
}

.selector-none {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.text-selectable {
    // make text selectable for copy
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.blue-back-grad {
    background: #1A2980;
    background: -webkit-linear-gradient(to bottom, #26D0CE, #1A2980);
    background: linear-gradient(to bottom, #26D0CE, #1A2980);
}

.red-grad {
    /* background: -webkit-linear-gradient(to bottom, #F45C43, #EB3349); */
    /* background: linear-gradient(to bottom, #F45C43, #EB3349); */
    /* background: #e52d27; */
    /* background: -webkit-linear-gradient(to bottom, #b31217, #e52d27); */
    /* background: linear-gradient(to bottom, #b31217, #e52d27); */
    /* background: rgb(2, 0, 36); */
    /* background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(112, 21, 86, 1) 0%, rgba(255, 0, 0, 1) 62%); */
    background: #FF0401;
    background: -webkit-linear-gradient(top, #FF0401, #6A0101);
    background: -moz-linear-gradient(top, #FF0401, #6A0101);
    background: linear-gradient(to bottom, #FF0401, #6A0101);
}

.blue-grad {
    /* background: #1A2980; */
    /* background: -webkit-linear-gradient(to bottom, #26D0CE, #1A2980); */
    /* background: linear-gradient(to bottom, #26D0CE, #1A2980); */
    background: #4b6cb7;
    background: -webkit-linear-gradient(to bottom, #4b6cb7, #182848);
    background: linear-gradient(to bottom, #4b6cb7, #182848);
}

.purple-grad {
    background: #DA22FF;
    background: -webkit-linear-gradient(to bottom, #DA22FF, #9733EE);
    background: linear-gradient(to bottom, #DA22FF, #9733EE);
}

.temp-grad {
    background: #02AAB0;
    background: -webkit-linear-gradient(to bottom, #00CDAC, #02AAB0);
    background: linear-gradient(to bottom, #00CDAC, #02AAB0);
}

.back-grad {
    background: #1A2980;
    background: -webkit-linear-gradient(to bottom, #26D0CE, #1A2980);
    background: linear-gradient(to bottom, #26D0CE, #1A2980);
}

.paleblue-grad {
    background: #2193b0;
    background: -webkit-linear-gradient(to bottom, #6dd5ed, #2193b0);
    background: linear-gradient(to bottom, #6dd5ed, #2193b0);
}

.side-grad {
    /* background: #2193b0; */
    /* background: -webkit-linear-gradient(to bottom, #6dd5ed, #2193b0); */
    /* background: linear-gradient(to bottom, #6dd5ed, #2193b0); */
    background-color: var(--color-side-nav);
}

.loading-shimmer {
    background-color: var(--color-loader);
    background-image: -webkit-gradient(linear, left top, right top, from(var(--color-loader)), color-stop(var(--color-loader-dim)), to(var(--color-loader)));
    background-image: linear-gradient(90deg, var(--color-loader), var(--color-loader-dim), var(--color-loader));
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* border-radius: 4px; */
    /* display: inline-block; */
    line-height: 1;
    /* width: 100% */
    -webkit-animation: shimmerLoader 1.8s ease-in-out infinite;
    animation: shimmerLoader 1.8s ease-in-out infinite
}

@-webkit-keyframes shimmerLoader {
    0% {
        background-position: -500px 0
    }
    to {
        background-position: calc(500px + 100%) 0
    }
}

@keyframes shimmerLoader {
    0% {
        background-position: -500px 0
    }
    to {
        background-position: calc(500px + 100%) 0
    }
}

@keyframes active-ripple {
    0% {
        box-shadow: 0 0 0 .1rem rgba(85, 255, 0, 0.3), 0 0 0 .5rem rgba(85, 255, 0, 0.2), 0 0 0 .5rem rgba(85, 255, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 .4rem rgba(85, 255, 0, 0.2), 0 0 0 .6rem rgba(85, 255, 0, 0.2), 0 0 0 1.0rem rgba(85, 255, 0, 0);
    }
    /* 0% {
        box-shadow: 0 0 0 .7rem rgba(85, 255, 0, 0.2), 0 0 0 1.5rem rgba(85, 255, 0, 0.2), 0 0 0 5rem rgba(85, 255, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 1.5rem rgba(85, 255, 0, 0.2), 0 0 0 4rem rgba(85, 255, 0, 0.2), 0 0 0 8rem rgba(85, 255, 0, 0);
    } */
    /* 0% {
        box-shadow: 0 0 0 0rem rgba(var(--color-active), 0.2), 0 0 0 1rem rgba(var(--color-active), 0.2), 0 0 0 2rem rgba(var(--color-active), 0.2), 0 0 0 5rem rgba(var(--color-active), 0.2);
    }
    100% {
        box-shadow: 0 0 0 1rem rgba(var(--color-active), 0.2), 0 0 0 2rem rgba(var(--color-active), 0.2), 0 0 0 5rem rgba(var(--color-active), 0.2), 0 0 0 8rem rgba(var(--color-active), 0);
    } */
}

.lds-dual-ring {
    display: inline-block;
}

.lds-dual-ring-sm {
    display: inline-block;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 36px;
    height: 36px;
    margin: 0;
    border-radius: 50%;
    // border: 2px solid var(--color-loader);
    // border-color: var(--color-loader) transparent var(--color-loader) transparent;
    border: 2px solid white;
    border-color: white transparent white transparent;
    animation: lds-dual-ring .5s linear infinite;
}

.lds-dual-ring-sm:after {
    content: " ";
    display: block;
    width: 24px;
    height: 24px;
    margin: 0;
    border-radius: 50%;
    // border: 2px solid var(--color-loader);
    // border-color: var(--color-loader) transparent var(--color-loader) transparent;
    border: 2px solid white;
    border-color: white transparent white transparent;
    animation: lds-dual-ring .5s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.def-loading-spinner {
    @extend .lds-dual-ring;
}

.def-loading-spinner-sm {
    @extend .lds-dual-ring-sm;
}

.border-dash-anim {
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, var(--color-view-def-bg) 50%, transparent 50%), linear-gradient(90deg, var(--color-view-def-bg) 50%, transparent 50%), linear-gradient(0deg, var(--color-view-def-bg) 50%, transparent 50%), linear-gradient(0deg, var(--color-view-def-bg) 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 16px 4px, 16px 4px, 4px 16px, 4px 16px;
    background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
    border-radius: 5px;
    padding: 10px;
    animation: dash 5s linear infinite;
}

@keyframes dash {
    to {
        background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
    }
}

span,
p,
div {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
}

#root-modal {
    width: 100vw;
    height: 100vh;
    position: fixed !important;
    z-index: 99999 !important;
    background: rgba(0, 0, 0, 0.481);
    display: none;
}


/* new DateRangePicker */

.rs-anim-fade,
.rs-anim-in,
.rs-picker-daterange-menu,
.placement-bottom-start,
.rs-picker-menu {
    position: fixed !important;
    z-index: 999 !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    overflow-x: hidden !important;
    overflow-y: auto !important;
    max-height: calc(100% - 50px) !important;
}

.rs-picker-daterange-panel {
    /* position: fixed; */
    /* z-index: 999; */
    top: 0;
}

.rs-picker-toggle {
    text-align: center !important;
    /* text-overflow: ellipsis; */
    /* white-space: normal; */
    /* width: 100%; */
}

.rs-picker-menu {
    background-color: var(--color-div-bg) !important;
    box-shadow: 0 0 3px 0px var(--color-shadow) !important;
}


/* .rs-picker-daterange-panel {
    overflow-x: hidden !important;
    overflow-y: auto !important;
} */

.rs-picker-daterange-header {
    color: var(--color-edit-txt) !important;
    border-bottom: 0px solid var(--color-border) !important;
    /* box-shadow: 0 0 4px -1.5px var(--color-border-partial) !important; */
}


/* .rs-picker-daterange-menu .rs-calendar:first-child {
    border-right: 1px solid #e5e5ea;
    border-right: 1px solid var(--rs-border-primary);
} */

.rs-calendar {
    height: 274px !important;
}

.rs-picker-daterange-menu .rs-calendar:first-child {
    border-right: 0px solid var(--color-border) !important;
    box-shadow: 1px 0 1px -1px var(--color-border);
}

.rs-picker-toolbar {
    /* width: 100% !important; */
    /* padding: 12px 34px 12px 34px !important; */
    border-top: 0px solid var(--color-border) !important;
    /* box-shadow: 0 0 4px -1.5px var(--color-border-partial) !important; */
}

.rs-picker-daterange-calendar-group {
    position: relative;
    box-shadow: 0 0 1px 0px var(--color-border);
}

.rs-calendar-time-dropdown {
    border-top: 0px solid var(--color-border) !important;
    background-color: var(--color-view-bg-sec) !important;
}

.rs-calendar-time-dropdown-column-title {
    border-top: 0px solid var(--color-border) !important;
    background-color: transparent !important;
}

.rs-calendar-time-dropdown-column ul {
    box-shadow: 0 0 3px -2px var(--color-border) !important;
}

.rs-calendar-month-dropdown {
    border-top: 0px var(--color-border) !important;
    background-color: var(--color-view-bg-sec) !important;
}

.rs-calendar-month-dropdown-row:not(:last-child) {
    border-bottom: 0px var(--color-border) !important;
    box-shadow: 0 0 2px -1.5px var(--color-border) !important;
}

.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover {
    color: var(--rs-text-disabled) !important;
}

.rs-calendar-time-dropdown-cell {
    color: var(--color-edit-txt) !important;
}

.rs-btn-link {
    /* color: #0a5dc2; */
    color: var(--color-edit-txt-sec) !important;
}

.rs-calendar-table-cell-day {
    color: var(--color-edit-txt) !important;
}

.rs-calendar-month-dropdown-row {
    padding: 5px 12px 5px 52px;
    margin: 5px 12px 5px 12px;
}

.rs-calendar-month-dropdown-year {
    left: 0 !important;
    margin: 0 24px 0 12px !important;
}

.rs-calendar-month-dropdown-list {
    width: 250px !important;
    margin: 0 12px 0 0 !important;
}

.rs-calendar-show-time-dropdown .rs-calendar-header-title-time {
    color: var(--color-edit-txt-sec) !important;
}

.rs-calendar-time-dropdown {
    box-shadow: 0 0 2px -1.5px var(--color-border) !important;
    margin-bottom: 200px !important;
}

.rs-picker-toggle-wrapper:focus,
.rs-picker-toggle-wrapper:hover {
    background-color: var(--color-div-bg) !important;
}

.rs-picker-subtle .rs-btn-active,
.rs-picker-subtle .rs-picker-toggle-active {
    color: var(--color-edit-txt) !important;
    background-color: var(--color-hovered-btn) !important;
}

.rs-picker-subtle .rs-btn:focus,
.rs-picker-subtle .rs-btn:hover,
.rs-picker-subtle .rs-picker-toggle:focus,
.rs-picker-subtle .rs-picker-toggle:hover {
    color: var(--color-edit-txt) !important;
    background-color: var(--color-hovered-btn) !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: var(--color-edit-txt) !important;
}

.rs-picker-toggle-textbox {
    border: 0px solid transparent !important;
    color: var(--color-edit-txt) !important;
    /* background-color: var(--color-div-bg) !important; */
    background: var(--color-main-screen-bg) !important;
}

.rs-picker-menu input {
    color: var(--color-edit-txt) !important;
}

@media screen and (max-width: 850px) {
    /* new date-range-picker */
    /* .rs-picker-toggle-textbox {
        width: 80% !important;
        padding: 0 !important;
    } */
    .rs-picker-toggle {
        text-align: center !important;
        /* text-overflow: ellipsis; */
        white-space: normal !important;
        /* width: 100%; */
    }
    .rs-picker-daterange-panel {
        height: auto !important;
        width: 300px !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        position: relative !important;
        box-sizing: border-box !important;
    }
    .rs-picker-daterange-calendar-group {
        height: auto !important;
        width: 100px !important;
        display: flex !important;
        flex-direction: column !important;
    }
    .rs-picker-daterange-header {
        text-align: center !important;
        width: 100% !important;
        /* height: 50px !important; */
        /* white-space: normal !important;
        padding: 12px 12px 12px 12px !important; */
        font-size: 12px !important;
    }
    .rs-calendar-header {
        width: 80% !important;
    }
    .rs-calendar {
        width: 100% !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .rs-picker-daterange-menu .rs-calendar:first-child {
        border-left: none !important;
        border-right: none !important;
        border-top: 0px solid var(--color-border-partial) !important;
        border-bottom: 0px solid var(--color-border-partial) !important;
        box-shadow: 0 2px 2px -2px var(--color-border-partial) !important;
    }
    .rs-picker-toolbar {
        width: 100% !important;
        padding: 12px 12px 12px 12px !important;
    }
    .rs-picker-daterange-menu .rs-calendar-header {
        width: 100% !important;
        text-align: center;
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
    }
    /* .rs-calendar-time-dropdown {
        width: 100% !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    } */
    .rs-calendar-time-dropdown-content {
        width: 75% !important;
        margin-left: 46px !important;
        margin-right: 22px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .rs-calendar-time-dropdown-row {
        display: flex !important;
        flex-direction: row !important;
        width: 95% !important;
        align-items: center;
        justify-content: center;
    }
    .rs-calendar-time-dropdown-column {
        width: 30% !important;
    }
    .rs-calendar-time-dropdown-column-title {
        width: 75% !important;
        text-align: center !important;
    }
    .rs-calendar-time-dropdown-column ul {
        width: 75% !important;
    }
    .rs-calendar-month-dropdown-row {
        /* width: 90% !important; */
        padding: 5px 12px 5px 52px !important;
        margin: 5px 12px 5px 70px !important;
    }
}

.lds-hourglass {
    display: inline-block;
}

//  https://loading.io/css/
.lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        transform: rotate(1800deg);
    }
}

.lds-dual-ring {
    display: inline-block;
}

.lds-dual-ring-sm {
    display: inline-block;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 36px;
    height: 36px;
    margin: 0;
    border-radius: 50%;
    border: 2px solid var(--color-loader);
    border-color: var(--color-loader) transparent var(--color-loader) transparent;
    animation: lds-dual-ring .5s linear infinite;
}

.lds-dual-ring-sm:after {
    content: " ";
    display: block;
    width: 24px;
    height: 24px;
    margin: 0;
    border-radius: 50%;
    border: 2px solid var(--color-loader);
    border-color: var(--color-loader) transparent var(--color-loader) transparent;
    animation: lds-dual-ring .5s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.def-loading-spinner {
    @extend .lds-dual-ring;
}

.def-loading-spinner-sm {
    @extend .lds-dual-ring-sm;
}

.show-popup-anim {
    bottom: calc(100% + 4px) !important;
}

// @keyframes show-unread-count-container {
//     // 0% {
//     //     // opacity: 0;
//     //     bottom: 50%;
//     // }
//     // 100% {
//     //     // opacity: 1;
//     //     bottom: calc(100% + 4px);
//     // }
// }
// @keyframes hide-unread-count-container {
//     // 0% {
//     //     bottom: calc(100% + 4px);
//     // }
//     // 100% {
//     //     bottom: 50%;
//     // }
// }
.stream-txt-online {
    color: green !important;
}

.stream-txt-offline {
    color: red !important;
}

.border-dash-anim {
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, var(--color-view-def-bg) 50%, transparent 50%), linear-gradient(90deg, var(--color-view-def-bg) 50%, transparent 50%), linear-gradient(0deg, var(--color-view-def-bg) 50%, transparent 50%), linear-gradient(0deg, var(--color-view-def-bg) 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 16px 4px, 16px 4px, 4px 16px, 4px 16px;
    background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
    border-radius: 5px;
    padding: 10px;
    animation: dash 5s linear infinite;
}

@keyframes dash {
    to {
        background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
    }
}

.highcharts-background {
    /* background-color: var(--color-div-bg) !important; */
    fill: var(--color-div-bg);
    /* display: none; */
}

.highcharts-legend-item text {
    fill: var(--color-edit-txt) !important;
}

.highcharts-grid-line {
    fill: none;
    stroke: #D8D8D8;
}

.highcharts-xaxis-grid .highcharts-grid-line {
    stroke-width: 0;
}


/* Tooltip */


/* .highcharts-tooltip {
    cursor: default;
    pointer-events: none;
    white-space: nowrap;
    transition: stroke 150ms;
    filter: url(#drop-shadow);
}

.highcharts-tooltip text {
    fill: #ffffff;
    color: white;
}

.highcharts-tooltip .highcharts-header {
    font-size: 0.85em;
}

.highcharts-tooltip-box {
    stroke-width: 1px;
    fill: rgba(0, 0, 0, 0.85);
    fill-opacity: 0.85;
} */

.highcharts-grid-line {
    fill: none;
    stroke: #d8d8d89a;
}

.highcharts-minor-grid-line {
    fill: none;
    stroke: #d8d8d843;
}

.chart-modal {
    display: none;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.501);
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chart-modal-content-lay {
    width: 80%;
    height: 60%;
    min-width: 300px;
    max-width: 800px;
    min-height: 300px;
    max-height: 450px;
    /* margin: auto; */
    padding: 0px;
    border-width: 1px;
    border-radius: 8px;
    border-color: var(--color-border);
    background-color: var(--color-div-bg);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    /* animation-name: animate-top;
    animation-duration: 0.2s; */
    /* overflow: hidden; */
}

.chart-modal-content {
    width: 99%;
    height: 92%;
    padding: 4px 4px 4px 4px;
    margin: -5% 0 0 0;
    position: relative;
    box-sizing: border-box;
}


/* @keyframes animate-top {
    from {
        bottom: -300px;
        opacity: 0
    }
    to {
        bottom: 0;
        opacity: 1
    }
} */


/* .second-row .bottom-column-1 .graph .growth-chart {
    width: 98%;
    height: 90%;
    color: var(--color-text-primary);
    margin: 4px;
    position: relative !important;
} */


/* date picker customizations */


/* .react-datepicker-wrapper {
    position: absolute;
    width: 100% !important;
}

.react-datepicker {
    background-color: var(--color-div-bg) !important;
    border: 1px var(--color-border) !important;
    box-shadow: 0 0 5px 1px var(--color-border) !important;
}

.react-datepicker__input-container input {
    display: none !important;
    position: absolute;
    left: 0;
    width: 10px !important;
    outline: none !important;
    border: none !important;
    color: transparent !important;
    cursor: pointer;
}

.react-datepicker__header {
    background-color: var(--color-primary) !important;
    border-bottom: 0px rgba(255, 255, 255, 0) !important;
    box-shadow: 0 0 3px -1px var(--color-border) !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: var(--color-text-title) !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker_time-name {
    color: var(--color-edit-txt) !important;
}

.react-datepicker__month-container {
    color: var(--color-edit-txt) !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover,
.react-datepicker__time-list-item:hover {
    color: var(--color-edit-txt-inverted) !important;
    background-color: var(--color-btn-active) !important;
}

.react-datepicker__time-container {
    float: left !important;
    border-left: 0px rgba(255, 255, 255, 0) !important;
}

.react-datepicker__time-container .react-datepicker__time {
    background: var(--color-primary) !important;
    color: var(--color-edit-txt) !important;
} */


/* new DateRangePicker */

.rs-picker-toggle {
    text-align: center !important;
    /* text-overflow: ellipsis; */
    /* white-space: normal; */
    /* width: 100%; */
}

.rs-picker-menu {
    background-color: var(--color-div-bg) !important;
    box-shadow: 0 0 3px 0px var(--color-shadow) !important;
}

.rs-picker-daterange-header {
    color: var(--color-edit-txt) !important;
    border-bottom: 0px solid var(--color-border-partial) !important;
    /* box-shadow: 0 0 4px -1.5px var(--color-border-partial) !important; */
}


/* .rs-picker-daterange-menu .rs-calendar:first-child {
    border-right: 1px solid #e5e5ea;
    border-right: 1px solid var(--rs-border-primary);
} */

.rs-calendar {
    height: 274px !important;
}

.rs-picker-daterange-menu .rs-calendar:first-child {
    border-right: 0px solid var(--color-border-partial) !important;
    box-shadow: 1px 0 1px -1px var(--color-border-partial);
}


/* .rs-picker-daterange-menu .rs-calendar:last-child {
    border-left: 0px solid var(--color-border-partial) !important;
    box-shadow: 0 0 1px 1px var(--color-border-partial);
} */

.rs-picker-toolbar {
    /* width: 100% !important; */
    /* padding: 12px 34px 12px 34px !important; */
    border-top: 0px solid var(--color-border-partial) !important;
    /* box-shadow: 0 0 4px -1.5px var(--color-border-partial) !important; */
}

.rs-picker-daterange-calendar-group {
    position: relative;
    box-shadow: 0 0 1px 0px var(--color-border-partial);
}

.rs-calendar-time-dropdown {
    border-top: 0px solid var(--color-border-partial) !important;
    background-color: var(--color-div-bg) !important;
}

.rs-calendar-time-dropdown-column-title {
    border-top: 0px solid var(--color-border-partial) !important;
    background-color: transparent !important;
}

.rs-calendar-time-dropdown-column ul {
    box-shadow: 0 0 3px -2px var(--color-border-partial) !important;
}

.rs-calendar-month-dropdown {
    border-top: 0px var(--color-border-partial) !important;
    background-color: var(--color-div-bg) !important;
}

.rs-calendar-month-dropdown-row:not(:last-child) {
    border-bottom: 0px var(--color-border-partial) !important;
    box-shadow: 0 0 2px -1.5px var(--color-border-partial) !important;
}

.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover {
    color: var(--rs-text-disabled) !important;
}

.rs-calendar-time-dropdown-cell {
    color: var(--color-edit-txt) !important;
}

.rs-btn-link {
    /* color: #0a5dc2; */
    color: var(--color-txt-pri) !important;
}

.rs-calendar-table-cell-day {
    color: var(--color-edit-txt) !important;
}

.rs-calendar-month-dropdown-row {
    padding: 5px 12px 5px 52px;
    margin: 5px 12px 5px 12px;
}

.rs-calendar-month-dropdown-year {
    left: 0 !important;
    margin: 0 24px 0 12px !important;
}

.rs-calendar-month-dropdown-list {
    width: 250px !important;
    margin: 0 12px 0 0 !important;
}

.rs-calendar-show-time-dropdown .rs-calendar-header-title-time {
    color: var(--color-txt-pri) !important;
}

.rs-calendar-time-dropdown {
    box-shadow: 0 0 2px -1.5px var(--color-border-partial) !important;
    margin-bottom: 200px !important;
}

.rs-picker-toggle-wrapper:focus,
.rs-picker-toggle-wrapper:hover {
    background-color: var(--color-div-bg) !important;
}

.rs-picker-subtle .rs-btn-active,
.rs-picker-subtle .rs-picker-toggle-active {
    color: var(--color-edit-txt) !important;
    background-color: var(--color-hovered-btn) !important;
}

.rs-picker-subtle .rs-btn:focus,
.rs-picker-subtle .rs-btn:hover,
.rs-picker-subtle .rs-picker-toggle:focus,
.rs-picker-subtle .rs-picker-toggle:hover {
    color: var(--color-edit-txt) !important;
    background-color: var(--color-hovered-btn) !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: var(--color-edit-txt) !important;
}

.rs-picker-toggle-textbox {
    border: 0px solid transparent !important;
    color: var(--color-edit-txt) !important;
    background-color: var(--color-div-bg) !important;
}

.rs-picker-menu input {
    color: var(--color-edit-txt) !important;
}


/* .rs-picker-toggle-wrapper {
    max-width: 100% !important;
} */


/* .rs-picker-toggle.rs-btn {
    padding-right: 26px !important;
} */

@media screen and (max-width: 850px) {
    .rs-picker-toggle {
        text-align: center !important;
        /* text-overflow: ellipsis; */
        white-space: normal !important;
        /* width: 100%; */
    }
    .rs-picker-daterange-panel {
        height: auto !important;
        width: 300px !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        position: relative !important;
        box-sizing: border-box !important;
    }
    .rs-picker-daterange-calendar-group {
        height: auto !important;
        width: 100px !important;
        display: flex !important;
        flex-direction: column !important;
    }
    .rs-picker-daterange-header {
        text-align: center !important;
        width: 100% !important;
        /* height: 50px !important; */
        /* white-space: normal !important;
        padding: 12px 12px 12px 12px !important; */
        font-size: 12px !important;
    }
    .rs-calendar-header {
        width: 80% !important;
    }
    .rs-calendar {
        width: 100% !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .rs-picker-daterange-menu .rs-calendar:first-child {
        border-left: none !important;
        border-right: none !important;
        border-top: 0px solid var(--color-border-partial) !important;
        border-bottom: 0px solid var(--color-border-partial) !important;
        box-shadow: 0 2px 2px -2px var(--color-border-partial) !important;
    }
    .rs-picker-toolbar {
        width: 100% !important;
        padding: 12px 12px 12px 12px !important;
    }
    .rs-picker-daterange-menu .rs-calendar-header {
        width: 100% !important;
        text-align: center;
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
    }
    /* .rs-calendar-time-dropdown {
        width: 100% !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    } */
    .rs-calendar-time-dropdown-content {
        width: 75% !important;
        margin-left: 46px !important;
        margin-right: 22px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .rs-calendar-time-dropdown-row {
        display: flex !important;
        flex-direction: row !important;
        width: 95% !important;
        align-items: center;
        justify-content: center;
    }
    .rs-calendar-time-dropdown-column {
        width: 30% !important;
    }
    .rs-calendar-time-dropdown-column-title {
        width: 75% !important;
        text-align: center !important;
    }
    .rs-calendar-time-dropdown-column ul {
        width: 75% !important;
    }
    .rs-calendar-month-dropdown-row {
        /* width: 90% !important; */
        padding: 5px 12px 5px 52px !important;
        margin: 5px 12px 5px 70px !important;
    }
}