.header {
    width: 100vw;
    /* width: calc(100% - var(--iot-nav-width)); */
    height: var(--iot-header-height);
    background-color: var(--color-toolbar-bg);
    position: fixed;
    z-index: 15;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // box-shadow: 0 3px 2px -1px var(--color-shadow);
    border-bottom: 1px solid var(--color-border);
    .menu-btn {
        position: absolute;
        left: 0;
        margin: 0 0 0 10px;
        cursor: pointer;
        display: none;
        color: var(--color-icon);
        font-size: 24px;
    }
    .app-title {
        font-size: 20px;
        font-weight: bold;
        line-height: 6px;
        word-spacing: 2px;
        color: var(--color-txt-pri);
        padding: 0px;
        margin: 0 8px 0 8px;
        justify-content: center;
        text-align: center;
    }
    .header-right-lay {
        width: auto;
        height: 68%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        margin: 0 0 0 0;
        .loading-lay {
            width: 50px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            margin: 0 10px 0 0;
            // background-color: var(--color-iot-bg);
            // border-radius: 50px;
        }
        .user-lay {
            width: auto;
            height: 100%;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;
            margin: 0 10px 0 0;
            background-color: var(--color-iot-bg);
            border-radius: 50px;
            .user-name {
                font-size: 13px;
                color: var(--color-edit-txt);
                margin: 0 8px 0 16px;
            }
            .user-icon {
                font-size: 20px;
                color: var(--color-edit-txt);
                margin: 0 8px 0 16px;
            }
        }
        .pallete-container {
            width: 70px;
            height: 100%;
            display: flex;
            justify-content: center;
            position: relative;
            cursor: pointer;
            margin: 0 10px 0 0;
            .switch {
                display: inline-block;
                width: 60px;
                height: 34px;
                position: relative;
                .toggle-thumb {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: var(--color-iot-bg);
                    border-radius: 50px;
                    cursor: pointer;
                    /* overflow: hidden; */
                    &:before {
                        content: "";
                        height: 26px;
                        width: 26px;
                        position: absolute;
                        left: 4px;
                        bottom: 4px;
                        border-radius: 50%;
                        background-color: var(--color-pallete-toggle);
                        transition: .4s all ease;
                    }
                }
                .checkbox {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }
                .checkbox:checked+.toggle-thumb:before {
                    transform: translateX(26px);
                }
            }
        }
    }
}

// @media (hover: hover) {
//     .header {
//         width: calc(100vw - 8px) !important;
//     }
// }

/*.header .pallete-container svg:nth-child(1) {
  position: relative;
  left: -26px;
}

.header .pallete-container svg {
  transition: .4s all ease;
} */


/* .header .checkbox:checked+.toggle-thumb>svg {
  transform: translateX(26px);
} */

@media screen and (max-width: 1020px) {
    .header {
        width: 100%;
        .menu-btn {
            display: block;
        }
    }
}

@media screen and (max-width: 850px) {
    .header {
        justify-content: flex-start;
        align-items: left;
        .app-title {
            font-size: 16px;
            margin: 0 10px 0 45px;
        }
        .header-right-lay {
            .loading-lay {
                margin: 0 2px 0 0;
            }
            .user-lay {
                margin: 0 4px 0 0;
                .user-name {
                    display: none;
                }
                .user-icon {
                    font-size: 20px;
                    // color: var(--color-text-secondary);
                    margin: 0 12px 0 12px;
                }
            }
            .pallete-container {
                margin: 0 4px 0 0;
            }
        }
    }
}