 .map-cont {
     width: 100%;
     height: 100%;
     border-width: 1px;
     border-radius: 8px;
     border-color: var(--color-border);
     position: relative;
     /* position: fixed;
     z-index: 99;
     height: 100vh; */
     background-color: var(--color-div-bg);
     * {
         transition: none;
         border-color: inherit;
         border-radius: 8px;
     }
     img {
         border-radius: 0px;
     }
     .loading-map {
         width: 100%;
         height: 100%;
         position: absolute;
         z-index: 1;
         background-color: var(--color-div-bg);
         display: flex;
         flex-direction: column;
         justify-content: center;
         text-align: center;
         /* margin: 0 0 10px 0; */
     }
     .item-icon {
         width: 30%;
         /* color: var(--color-text-title); */
         color: var(--color-icon) !important;
         font-size: 50px;
         position: absolute;
         z-index: 4;
         top: 0;
         right: 0;
         text-align: right;
         padding: 16px 12px 0 0;
         /* background-color: #000; */
     }
     .loc-title {
         width: 120px !important;
         color: var(--color-edit-txt) !important;
         font-size: 24px;
         position: absolute;
         z-index: 4;
         top: 0;
         left: 0;
         /* text-align: left; */
         /* margin: 26px 16px 0 12px; */
         padding: 8px 8px;
         margin: 16px 0 0 10px;
         box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.30);
         border-radius: 8px;
         background-color: var(--color-div-bg);
     }
     .loc-title-dashboard {
         width: 90px !important;
         color: var(--color-edit-txt) !important;
         font-size: 16px;
         position: absolute;
         z-index: 2;
         top: 0;
         left: 0;
         /* text-align: left; */
         /* margin: 26px 16px 0 12px; */
         padding: 4px 8px;
         margin: 8px 0 0 10px;
         box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.30);
         border-radius: 8px;
         background-color: var(--color-div-bg);
     }
     .loc-title-setting {
         width: auto !important;
         font-size: 14px;
         color: var(--color-edit-txt) !important;
         font-size: 16px;
         position: absolute;
         z-index: 2;
         top: 0;
         left: 0;
         padding: 4px 8px;
         margin: 8px 0 0 10px;
         box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.30);
         border-radius: 8px;
         background-color: var(--color-div-bg);
         cursor: default;
     }
     .loc-setting-btns {
         width: auto !important;
         height: 30px;
         font-size: 16px;
         position: absolute;
         z-index: 2;
         top: 0;
         left: 0;
         padding: 4px 8px;
         margin: 8px 0 0 10px;
         display: flex;
         flex-direction: row;
         justify-content: flex-start;
         align-items: center;
         .loc-draw-btn {
             width: auto;
             font-size: 16px;
             position: relative;
             padding: 4px 8px;
             margin: 0 4px 0 0;
             box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.30);
             border-radius: 8px;
             font-size: 14px;
             color: var(--color-view-txt-active);
             background: var(--color-view-bg-active);
             cursor: pointer;
             &:hover {
                 background: var(--color-view-bg-active) !important;
                 color: var(--color-view-txt-active) !important;
             }
         }
         .loc-remove-btn {
             width: auto;
             font-size: 16px;
             position: relative;
             padding: 4px 8px;
             margin: 0 0 0 4px;
             box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.30);
             border-radius: 8px;
             font-size: 14px;
             color: var(--color-view-txt-active);
             background: #ff1010;
             cursor: pointer;
             &:hover {
                 background: #c20303;
                 color: var(--color-view-txt-active);
             }
         }
     }
     .center-input-lay {
         width: auto !important;
         height: 30px;
         font-size: 16px;
         position: absolute;
         z-index: 2;
         top: 38px;
         left: 0;
         padding: 4px 8px;
         margin: 8px 0 0 10px;
         display: flex;
         flex-direction: row;
         justify-content: flex-start;
         align-items: center;
         border-radius: 8px;
         background-color: var(--color-div-bg);
         box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.30);
         cursor: pointer;
         .center-input-field {
             width: 14px;
             height: 14px;
             position: relative;
             border-radius: 50%;
             border: 1px solid var(--color-border);
             background-color: var(--color-div-bg);
             padding: 8px;
             margin: 0 8px 0 0;
         }
         .center-input-text {
             width: auto;
             text-align: left;
             font-size: 14px;
             color: var(--color-edit-txt);
         }
     }
     .loc-info {
         width: 100%;
         height: 100%;
         color: var(--color-edit-txt);
         font-size: 18px;
         font-weight: normal;
         font-style: inherit;
         display: flex;
         position: absolute;
         z-index: 2;
         top: 0;
         right: 0;
         bottom: 0;
         left: 0;
         text-align: center;
         margin: 0 0 0 0;
         /* background: rgb(255, 27, 27); */
         text-align: center;
         justify-content: center;
         align-items: center;
     }
     a {
         background-color: transparent;
         -webkit-text-decoration-skip: objects;
     }
     a:-webkit-any-link {
         color: -webkit-link;
         cursor: pointer;
         text-decoration: underline;
     }
 }
 
 .marker-container {
     position: relative;
     word-spacing: 2px;
     .marker-modal {
         cursor: initial;
         visibility: visible;
         position: absolute;
         transform: translate(-50%);
         bottom: 6px;
         padding: 10px;
         margin: 0 0 30px 0;
         background-color: var(--color-div-bg);
         box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
         border-radius: 15px;
         width: 200px;
         z-index: 99999;
         pointer-events: all;
         .marker-modal-name {
             font-family: sans-serif;
             font-size: 14px;
             margin-bottom: 8px;
             font-weight: 900;
             color: var(--color-edit-txt);
         }
         .marker-modal-address {
             /* color: rgb(102, 102, 102); */
             color: var(--color-edit-txt);
             cursor: pointer;
             text-decoration: none;
             font-size: 12px;
             line-height: 22.5px;
             font-weight: 40;
             padding: 0;
             margin: 2px 0 2px 0;
             outline: none;
         }
         .marker-modal-notes {
             border: none;
             padding: 0px;
             width: 100%;
             resize: none;
             cursor: text;
             color: var(--color-text-footer);
             margin-top: 4px;
             max-height: 200px;
             overflow-y: auto;
             text-shadow: 0px 0px 0px rgb(57, 57, 57);
             font-size: 11px;
             line-height: 24.9px;
             font-weight: 700;
             p {
                 padding: 0;
                 margin: 0;
             }
         }
         .marker-modal-tail {
             background-color: var(--color-div-bg);
             width: 10px;
             height: 10px;
             position: absolute;
             bottom: -5px;
             left: calc(50% - 5px);
             transform: rotate( 45deg);
             z-index: -1;
         }
     }
     .marker-info-window {
         /* margin: 40px; */
         display: inline-block;
         position: relative;
         background-color: #fff;
         border: 8px solid #fff;
         border-radius: 50px;
         -webkit-border-radius: 50px;
         -moz-border-radius: 50px;
     }
     .marker-text {
         padding: 1em;
         text-align: left;
         line-height: 1.5em;
         p {
             /* remove webkit p margins */
             -webkit-margin-before: 0em;
             -webkit-margin-after: 0em;
         }
     }
     .marker-pointer {
         flex-direction: column;
         justify-content: center;
         align-items: center;
         text-align: center;
         font-size: 11px;
         color: #000;
         transition: opacity 200ms ease-in 0s;
         position: relative;
         // width: 60px;
         // height: 40px;
         // background: #fff !important;
         // border-width: 1px;
         // border-top-left-radius: 8px !important;
         // border-top-right-radius: 8px !important;
         // border-bottom-right-radius: 8px !important;
         // border-bottom-left-radius: 8px !important;
         // border-style: solid;
         // border-color: var(--color-border);
         // display: flex;
         // margin: -40px 0 0 0;
         // position: relative; 
         // text-align: center;
         // line-height: 100px;
         // background-color: #fff;
         // border: 8px solid #666;
         // -webkit-border-radius: 30px !important;
         // -moz-border-radius: 30px !important;
         // border-radius: 30px !important;
         // -webkit-box-shadow: 2px 2px 4px #888;
         // -moz-box-shadow: 2px 2px 4px #888;
         // box-shadow: 2px 2px 4px #888;
         .marker-shape {
             cursor: pointer;
             width: 8px;
             height: 8px;
             background-color: rgb(41, 98, 255);
             border: 3.84px solid white !important;
             box-sizing: content-box !important;
             transform: translate(-50%, -50%);
             border-radius: 50px !important;
             box-shadow: 0px 3.2px 6.4px 0px rgba(0, 0, 0, 0.15)!important;
             z-index: 9999;
             position: relative;
         }
     }
 }
 
 .marker-pointer-container {
     position: relative;
     word-spacing: 2px;
     .marker-modal {
         cursor: initial;
         visibility: visible;
         position: absolute;
         transform: translate(-50%);
         bottom: 35.84px;
         padding: 10px;
         margin: 0 0 30px 0;
         background-color: var(--color-div-bg);
         box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
         border-radius: 15px;
         width: 50px;
         z-index: 99999;
         pointer-events: all;
         .marker-modal-name {
             font-family: sans-serif;
             font-size: 14px;
             margin-bottom: 8px;
             font-weight: 900;
             color: var(--color-edit-txt);
         }
         .marker-modal-address {
             /* color: rgb(102, 102, 102); */
             color: var(--color-edit-txt);
             cursor: pointer;
             text-decoration: none;
             font-size: 12px;
             line-height: 22.5px;
             font-weight: 40;
             padding: 0;
             margin: 2px 0 2px 0;
             outline: none;
         }
         .marker-modal-notes {
             border: none;
             padding: 0px;
             width: 100%;
             resize: none;
             cursor: text;
             color: var(--color-text-footer);
             margin-top: 4px;
             max-height: 200px;
             overflow-y: auto;
             text-shadow: 0px 0px 0px rgb(57, 57, 57);
             font-size: 11px;
             line-height: 24.9px;
             font-weight: 700;
             p {
                 padding: 0;
                 margin: 0;
             }
         }
         .marker-modal-tail {
             background-color: var(--color-div-bg);
             width: 10px;
             height: 10px;
             position: absolute;
             bottom: -5px;
             left: calc(50% - 5px);
             transform: rotate( 45deg);
             z-index: -1;
         }
     }
     .marker-info-window {
         /* margin: 40px; */
         display: inline-block;
         position: relative;
         background-color: #fff;
         border: 8px solid #fff;
         border-radius: 50px;
         -webkit-border-radius: 50px;
         -moz-border-radius: 50px;
     }
     .marker-text {
         padding: 1em;
         text-align: left;
         line-height: 1.5em;
         p {
             /* remove webkit p margins */
             -webkit-margin-before: 0em;
             -webkit-margin-after: 0em;
         }
     }
     .marker-pointer {
         flex-direction: column;
         justify-content: center;
         align-items: center;
         text-align: center;
         font-size: 11px;
         color: #000;
         transition: opacity 200ms ease-in 0s;
         position: relative;
         .marker-shape {
             cursor: pointer;
             width: 8px;
             height: 8px;
             background-color: rgb(41, 98, 255);
             border: 3.84px solid white !important;
             box-sizing: content-box !important;
             transform: translate(-50%, -50%);
             border-radius: 50px !important;
             box-shadow: 0px 3.2px 6.4px 0px rgba(0, 0, 0, 0.15)!important;
             z-index: 9999;
             position: relative;
         }
         .marker-shape-current {
             cursor: pointer;
             width: 8px;
             height: 8px;
             //  background-color: rgb(241, 26, 7);
             background-color: rgb(255, 3, 3);
             border: 3.84px solid white !important;
             box-sizing: content-box !important;
             transform: translate(-50%, -50%);
             border-radius: 50px !important;
             box-shadow: 0px 3.2px 6.4px 0px rgba(0, 0, 0, 0.15)!important;
             z-index: 9999;
             position: relative;
         }
     }
 }
 /* .marker-pointer:before {
     content: ' ';
     position: relative;
     width: 0;
     height: 0;
     left: 30px;
     top: 100px;
     border: 5px solid;
     border-color: #666 transparent transparent #666;
     background: #fff !important;
 } */
 /* CSS talk bubble */
 /* .round {
     border-radius: 50px;
     -webkit-border-radius: 50px;
     -moz-border-radius: 50px;
 }
  .tri-right.border.btm-left-in:before {
     content: ' ';
     position: absolute;
     z-index: 0;
     width: 0;
     height: 0;
     left: 39px;
     right: auto;
     top: 38px;
     bottom: -5px;
     border: 11px solid;
     border-color: #000 transparent transparent #000;
 } 
  .tri-right.btm-left-in:after {
     content: ' ';
     position: absolute;
     z-index: 1;
     width: 0;
     height: 0;
     left: 40px;
     right: auto;
     top: auto;
     bottom: -20px;
     border: 12px solid;
     border-color: #fff transparent transparent #fff;
 } 
  .tri-right.border.btm-left-in:before {
    content: ' ';
    position: absolute;
    z-index: 0;
    width: 0;
    height: 0;
    left: 5px;
    right: auto;
    top: 38px;
    bottom: -5px;
    border: 11px solid;
    border-color: #000 transparent transparent #000;
}

.tri-right.btm-left-in:after {
    content: ' ';
    position: absolute;
    z-index: 1;
    width: 0;
    height: 0;
    left: 6px;
    right: auto;
    top: auto;
    bottom: -20px;
    border: 12px solid;
    border-color: #fff transparent transparent #fff;
} */