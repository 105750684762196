.dashboard-main {
    width: calc(100% - 16px);
    height: calc(100% - 8px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: var(--color-div-bg);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    margin: 4px 8px;
    padding: 0;
    .dashboard-title-lay {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        border-bottom: 1px solid var(--color-border);
        background-color: var(--color-toolbar-bg);
        z-index: 1;
        .dashboard-back-btn {
            width: 30px;
            height: 30px;
            font-size: 24px;
            color: var(--color-icon);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 4px;
            cursor: pointer;
        }
        .dashboard-title-text {
            font-size: 16px;
            font-weight: bold;
            color: var(--color-edit-txt);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;
        }
        .refresh-btn {
            width: auto;
            height: 30px;
            font-size: 14px;
            letter-spacing: 2px;
            text-decoration: none;
            color: var(--color-view-txt-active);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 4px;
            border-radius: 8px;
            background: var(--color-view-bg-active);
            padding: 4px 8px;
            cursor: pointer;
            &:hover {
                background: var(--color-view-bg-active);
                color: var(--color-view-txt-active);
                //transform: scale(1.04);
            }
        }
    }
    .dashboard-contents-lay {
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        .top-lay {
            width: calc(100% - 16px);
            height: auto;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            position: relative;
            overflow: hidden;
            .counts-cont {
                width: calc(50% - 8px);
                height: 58px;
                padding: 10px 0 8px 0;
                margin: 4px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                -webkit-box-sizing: none !important;
                box-sizing: content-box !important;
                .all-count-cont {
                    width: 50%;
                    height: 100%;
                    padding: 8px;
                    margin: 0 4px 0 0;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    border: 1px solid var(--color-border);
                    background-color: var(--color-div-bg);
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    box-sizing: content-box !important;
                    .all-count-icon {
                        width: 36px;
                        height: 36px;
                        padding: 4px;
                        margin: 2px 4px 2px 2px;
                        position: absolute;
                        right: 0;
                    }
                    .text-cont {
                        width: calc(100% - 36px);
                        margin: 0 36px 0 0;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    .all-count-title {
                        font-size: 13px;
                        color: var(--color-edit-txt);
                        /* font-weight: bold; */
                        /* padding: 0 8px 0 8px; */
                        margin: 2px;
                        white-space: nowrap;
                    }
                    .all-count-info {
                        font-size: 14px;
                        color: var(--color-edit-txt);
                        font-weight: bold;
                        /* padding: 0 8px 0 8px; */
                        margin: 2px;
                        white-space: nowrap;
                    }
                }
                .active-count-cont {
                    width: 50%;
                    height: 100%;
                    padding: 8px;
                    margin: 0 0 0 4px;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    border: 1px solid var(--color-border);
                    background-color: var(--color-div-bg);
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    box-sizing: content-box !important;
                    .active-count-icon {
                        width: 36px;
                        height: 36px;
                        padding: 4px;
                        margin: 2px 2px 2px 4px;
                        position: absolute;
                        background-color: var(--color-active);
                        border-radius: 50px;
                        left: 0;
                    }
                    .actual-active {
                        animation: active-ripple 3s linear infinite;
                        animation-delay: 0s;
                    }
                    .text-cont {
                        width: calc(100% - 36px);
                        margin: 0 0 0 36px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }
                    .active-count-title {
                        font-size: 13px;
                        color: var(--color-edit-txt);
                        /* font-weight: bold; */
                        padding: 0 8px 0 8px;
                        margin: 2px;
                        white-space: nowrap;
                    }
                    .active-count-info {
                        font-size: 14px;
                        color: var(--color-edit-txt);
                        font-weight: bold;
                        padding: 0 8px 0 8px;
                        margin: 2px;
                        white-space: nowrap;
                    }
                }
            }
            .time-count-cont {
                width: calc(50% - 8px);
                height: 58px;
                padding: 8px 0 8px 0;
                margin: 4px;
                border: 1px solid var(--color-border);
                border-radius: 8px;
                background-color: var(--color-div-bg);
                position: relative;
                box-sizing: content-box !important;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .range-display-lay {
                    width: 90%;
                    height: 25px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding: 2px;
                    margin: 4px 0 4px 0;
                    box-sizing: border-box;
                }
                .current-display-lay {
                    width: 90%;
                    height: 25px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    /* background-color: #b24bb7; */
                    padding: 2px;
                    margin: 4px 0 4px 0;
                }
                .range-display-lay .info,
                .current-display-lay .info {
                    font-size: 16px;
                    color: var(--color-edit-txt);
                }
            }
        }
        .second-lay {
            width: calc(100% - 16px);
            height: auto;
            position: relative;
            display: flex;
            flex-direction: row;
            overflow: hidden;
            justify-content: center;
            align-items: flex-start;
            flex-shrink: 0;
            .left-lay {
                width: calc(50% - 8px);
                height: 500px;
                margin: 4px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .average-lay {
                    width: 100%;
                    height: 200px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    position: relative;
                    margin: 0 0 4px 0;
                    .avg-left-cont {
                        width: 50%;
                        height: auto;
                        border-width: 1px;
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                        border: 1px solid var(--color-border);
                        background-color: var(--color-div-bg);
                        margin: 0 4px 0 0;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                    .avg-right-cont {
                        width: 50%;
                        height: 100%;
                        border-width: 1px;
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                        border: 1px solid var(--color-border);
                        background-color: var(--color-div-bg);
                        margin: 0 0 0 4px;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                    .avg-left-cont .temp-in-lay,
                    .avg-left-cont .temp-out-lay,
                    .avg-right-cont .avg-light-lay,
                    .avg-right-cont .avg-humi-lay {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                    }
                    .avg-left-cont .temp-in-lay .temp-in,
                    .avg-left-cont .temp-out-lay .temp-out,
                    .avg-right-cont .avg-light-lay .avg-light,
                    .avg-right-cont .avg-humi-lay .avg-humi {
                        width: 50%;
                        height: 100%;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                    .avg-knob {
                        width: 50%;
                        height: 100%;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    .text-title {
                        font-size: 13px;
                        color: var(--color-edit-txt);
                        position: absolute;
                        top: 0;
                        padding: 4px 0 0 8px;
                    }
                    .text-info {
                        font-size: 13px;
                        font-weight: bold;
                        color: var(--color-edit-txt);
                        padding: 0 6px 0 8px;
                    }
                }
                .dashboard-chart-lay {
                    width: 100%;
                    height: 300px;
                    margin: 4px 0 0 0;
                    border: 1px solid var(--color-border);
                    border-radius: 8px;
                    background-color: var(--color-div-bg);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    overflow-x: auto;
                    overflow-y: hidden;
                    position: relative;
                    .chart-lay {
                        width: 99%;
                        height: 100%;
                        padding: 4px;
                        position: absolute;
                        * {
                            transition: none;
                        }
                        .highcharts-container {
                            width: 100% !important;
                            height: 100% !important;
                            .highcharts-root {
                                width: 100% !important;
                                height: 100% !important;
                            }
                        }
                    }
                }
            }
            .right-lay {
                width: calc(50% - 8px);
                height: 500px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                border: 1px solid var(--color-border);
                border-radius: 8px;
                background-color: var(--color-div-bg);
                margin: 4px;
            }
        }
    }
    .dashboard-modal {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 0 0 0;
        background-color: rgba(0, 0, 0, 0.645);
        display: flex;
        z-index: 16;
    }
}


/* .dashboard-main .top-lay .active-count-cont .active-count-icon path {
    animation: active-ripple 1s linear infinite;
} */

@media screen and (max-width: 850px) {
    .dashboard-main {
        width: 100%;
        height: 100%;
        border-width: 0px;
        border-radius: 0px;
        margin: 0px;
        padding: 0;
        background-color: var(--color-iot-bg);
        .dashboard-contents-lay {
            .top-lay {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .counts-cont {
                    width: calc(100% - 8px);
                    margin: 4px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    .all-count-cont {
                        width: 50%;
                    }
                    .active-count-cont {
                        width: 50%;
                    }
                }
                .time-count-cont {
                    width: calc(100% - 8px);
                    margin: 4px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
            .second-lay {
                width: 100%;
                height: auto;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .left-lay {
                    width: 100%;
                    height: auto;
                    margin: 4px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .average-lay {
                        width: calc(100% - 8px);
                        height: 200px;
                    }
                    .dashboard-chart-lay {
                        width: calc(100% - 8px);
                        height: 400px;
                    }
                }
                .right-lay {
                    width: calc(100% - 16px);
                    height: calc(95vh - (50px + 40px + 8px));
                    margin: 8px;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}

.dashboard-main .dashboard-detail-item-loader {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    /*border-style: solid;*/
    position: absolute;
    z-index: 3;
    box-sizing: border-box;
}