.main-home-lay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    .side-nav-modal {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 0 0 0;
        background-color: rgba(0, 0, 0, 0.645);
        display: flex;
        z-index: 16;
        transition: none;
        * {
            transition: none;
        }
    }
    .active-modal {
        width: 100vw;
        height: 100vh;
    }
    .iot-main-contents {
        width: calc(100% - var(--iot-nav-width));
        height: 100vh;
        max-width: 1400px;
        position: relative;
        margin: var(--iot-header-height) 0 0 var(--iot-nav-width);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        .devices-main-lay {
            width: 100%;
            height: calc(100vh - var(--iot-header-height));
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            overflow: hidden;
            .devices-list-lay {
                width: calc(30% - 16px);
                height: calc(100% - 8px);
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                overflow: hidden;
                background-color: var(--color-div-bg);
                border: 1px solid var(--color-border);
                border-radius: 8px;
                margin: 8px;
                padding: 0;
                .devices-list-title-lay {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    border-bottom: 1px solid var(--color-border);
                    background-color: var(--color-toolbar-bg);
                    z-index: 1;
                    .devices-list-back-btn {
                        width: 30px;
                        height: 30px;
                        font-size: 24px;
                        color: var(--color-icon);
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        left: 4px;
                        cursor: pointer;
                    }
                    .devices-list-title-text {
                        font-size: 16px;
                        font-weight: bold;
                        color: var(--color-edit-txt);
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                    }
                }
                .devices-list-contents-lay {
                    width: 100%;
                    height: calc(100% - 40px);
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    position: relative;
                    overflow-x: hidden;
                    overflow-y: auto;
                    .device-item-loader {
                        width: calc(100% - 16px);
                        height: calc(48px - 8px);
                        margin: 4px 8px 4px 8px;
                        /* box-sizing: border-box; */
                        border-width: 1px;
                        border-radius: 8px;
                        flex-shrink: 0;
                        /*border-style: solid;*/
                        /* border-color: var(--color-border); */
                    }
                    .empty-data {
                        width: 100%;
                        height: 100vh;
                        overflow-x: hidden;
                        overflow-y: auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    .message-box {
                        color: rgb(255, 42, 42);
                        font-size: 12px;
                        font-weight: bold;
                        background: #ffffff;
                        box-sizing: border-box;
                        border-width: 1px;
                        border-radius: 8px;
                        /*border-style: solid;*/
                        border-color: var(--color-border);
                        padding: 12px 20px 12px 20px;
                        margin: 4px 8px 4px 8px;
                        flex-shrink: 0;
                        cursor: pointer;
                        &:hover {
                            color: #fff;
                            background: #8baef0;
                        }
                    }
                    .devices-location-cont {
                        width: calc(100% - 16px);
                        padding: 2px 0 2px 0;
                        margin: 4px 8px 4px 8px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        /* background: #73ff57; */
                        position: relative;
                        .devices-location-title-lay {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            padding: 0 0 0 8px;
                            .devices-location-title {
                                letter-spacing: 2px;
                                font-size: 15px;
                                color: var( --color-view-txt);
                                padding: 0 0 0 4px;
                            }
                        }
                        .device-item {
                            width: 100%;
                            box-sizing: border-box;
                            /* background: #ff5757; */
                            display: flex;
                            flex-direction: row;
                            padding: 0 0 0 8px;
                            .device-item-btn {
                                width: calc(90% - 16px);
                                letter-spacing: 2px;
                                font-size: 15px;
                                padding: 12px 20px 12px 20px;
                                margin: 4px 8px 4px 0;
                                box-sizing: border-box;
                                text-decoration: none;
                                cursor: pointer;
                                border-radius: 8px;
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: center;
                                flex-shrink: 0;
                                position: relative;
                                .item-icon {
                                    font-size: 18px;
                                    color: var(--color-icon);
                                    color: var(--color-view-txt);
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    position: relative;
                                    margin: 0 16px 0 0;
                                }
                                .item-text {
                                    font-size: 16px;
                                    color: var(--color-edit-txt);
                                    color: var(--color-view-txt);
                                }
                            }
                            .device-item-normal {
                                border: 1px solid var(--color-border);
                                //background: var(--color-view-bg);
                                background-color: var(--color-side-nav);
                                .item-icon {
                                    color: var(--color-view-txt);
                                }
                                .item-text {
                                    color: var(--color-view-txt);
                                }
                                &:hover {
                                    background: var(--color-view-bg-active);
                                    .item-icon {
                                        color: var(--color-view-txt-active);
                                    }
                                    .item-text {
                                        color: var(--color-view-txt-active);
                                    }
                                }
                            }
                            .device-item-selected {
                                background: var(--color-view-bg-active);
                                .item-icon {
                                    color: var(--color-view-txt-active);
                                }
                                .item-text {
                                    color: var(--color-view-txt-active);
                                }
                                &:hover {
                                    background: var(--color-view-bg-active);
                                    .item-icon {
                                        color: var(--color-view-txt-active);
                                    }
                                    .item-text {
                                        color: var(--color-view-txt-active);
                                    }
                                }
                            }
                        }
                        .lines-lay {
                            width: 10%;
                            /* background: #e2e2e2; */
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            .lines {
                                background: var(--color-edit-txt);
                            }
                            .vert-1 {
                                width: 1px;
                                height: 50%;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                            .vert-2 {
                                width: 1px;
                                height: 50%;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                            }
                            .horz {
                                width: 100%;
                                height: 1px;
                            }
                        }
                    }
                }
            }
            .device-details-lay {
                width: calc(70% - 8px);
                height: calc(100% - 8px);
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                overflow: hidden;
                background-color: var(--color-div-bg);
                border: 1px solid var(--color-border);
                border-radius: 8px;
                .device-item-empty-lay {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    .device-item-empty-text {
                        font-size: 16px;
                        font-weight: 400;
                        color: var(--color-edit-txt);
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                    }
                }
            }
        }
        .bottom-lay {
            width: 100%;
            height: 40px;
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            text-align: center;
            .bottom-container {
                span {
                    width: auto;
                    height: 40px;
                    padding: 0px;
                    margin: 0px 8px 0px 8px;
                    font-size: 14px;
                    color: var(--color-text-footer);
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    a {
                        color: var(--color-text-title);
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1021px) {
    .main-home-lay {
        .side-nav-modal {
            display: none !important;
        }
        .active-modal {
            display: none;
        }
    }
}

@media screen and (max-width: 1020px) {
    .main-home-lay {
        .iot-main-contents {
            width: 100%;
            position: relative;
            margin: var(--iot-header-height) 0 0 0;
        }
        .side-nav-modal {
            display: none;
        }
        .active-modal {
            display: flex !important;
        }
    }
}

@media screen and (max-width: 850px) {
    .main-home-lay {
        .iot-main-contents {
            .devices-main-lay {
                .devices-list-lay {
                    width: 100%;
                    height: 100%;
                    border-width: 0px;
                    border-radius: 0px;
                    margin: 0px;
                    padding: 0;
                }
                .device-details-lay {
                    width: 100%;
                    height: 100%;
                    border-width: 0px;
                    border-radius: 0px;
                    margin: 0px;
                    padding: 0;
                    display: none;
                    background-color: var(--color-iot-bg);
                }
                .device-details-with-item {
                    width: 100vw;
                    height: calc(100vh - var(--iot-header-height));
                    display: flex;
                    position: fixed;
                    z-index: 1;
                    top: var(--iot-header-height);
                }
            }
        }
    }
}